<template>
  <el-select
    v-model="operator"
    popper-class="elv-automation-rule-condition-input-popper"
    class="elv-automation-rule-condition-shorter-input"
    placeholder="Select"
    @change="() => emit('onChangeType')"
  >
    <el-option
      v-for="(item, i) in textOperatorOptions"
      :key="i"
      :label="transformI18n(item.label)"
      :value="item.value"
    />
  </el-select>

  <el-input
    v-if="['EQ', 'INCLUDES'].includes(operator)"
    v-model="value[0]"
    class="elv-automation-rule-condition-shorter-input"
    @change="() => emit('onChangeCondition')"
  />
  <SingleChoiceSelect
    v-else
    v-model="value"
    multiple
    clearable
    filterable
    default-first-option
    allow-create
    collapse-tags
    collapse-tags-tooltip
    :max-collapse-tags="1"
    placeholder="Input"
    class="elv-automation-rule-condition-shorter-input elv-elv-automation-rule-condition-text-input"
    :options="[]"
    @change="() => emit('onChangeCondition')"
  />
</template>

<script setup lang="ts">
import { $t, transformI18n } from '@/i18n/index'
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'

const value = defineModel<any>('value', { required: true })
const operator = defineModel<any>('operator', { required: true })

const emit = defineEmits(['onChangeType', 'onChangeCondition'])

const textOperatorOptions = reactive([
  { label: $t('common.is'), value: 'EQ' },
  { label: $t('common.includes'), value: 'INCLUDES' },
  { label: $t('common.in'), value: 'IN' },
  { label: $t('common.notIn'), value: 'NOT_IN' }
])
</script>

<style lang="scss">
.elv-elv-automation-rule-condition-text-input.elv-automation-rule-condition-shorter-input.elv-base-single-choice-select {
  .el-select__wrapper .el-tag {
    max-width: 76px !important;

    .el-select__tags-text span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
