<template>
  <div class="elv-automation-rule-action-entry">
    <template v-if="!!entryLineList.length">
      <div
        v-for="(item, index) in entryLineList"
        :key="item.index"
        class="elv-automation-rule-entry-item"
        :class="[item.balanceType === 'Dr' ? 'is-Dr' : 'is-Cr']"
      >
        <div class="elv-automation-rule-entry-title">
          <p>#{{ item.index + 1 }}</p>
          <span :class="{ 'is-Dr': item.balanceType === 'Dr' }">{{ item.balanceType }}</span
          >{{
            item.chartOfAccountCalculator === 'TREASURY_ACCOUNT'
              ? `{ ${t('report.treasuryAccount')} }`
              : locale === 'en'
                ? item?.chartOfAccount?.name
                : item?.chartOfAccount?.nameCN || item?.chartOfAccount?.name
          }}
        </div>
        <div
          v-if="
            !!entryLineList.length &&
            !props.loading &&
            (props.activeTab !== 'transfer' ||
              (props.activeTab === 'transfer' && !!props.entryLines?.[item.index]?.auxiliaryTypes?.length))
          "
          class="elv-automation-rule-entry-auxiliary-list"
        >
          <template v-if="props.activeTab !== 'transfer'">
            <div
              v-if="entryLineList?.[item.index]?.currency !== undefined"
              class="elv-automation-rule-entry-auxiliary-item"
            >
              <p class="elv-automation-rule-entry-auxiliary-item__title">
                {{ t('common.currency') }}
              </p>
              <el-select
                v-if="
                  currentChartOfAccount(item.chartOfAccountId).value?.currencyTypes?.[0] === 'REPORTING' ||
                  ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug)
                "
                :model-value="entityStore.entityDetail.defaultCurrency"
                :disabled="true"
              >
                <template #label>
                  <div class="elv-automation-rule-entry-reporting-currency">
                    <img
                      :src="entityStore.entityDetail.underlyingCurrency?.logo"
                      :alt="entityStore.entityDetail.underlyingCurrency?.name"
                    />
                    <p>{{ entityStore.entityDetail.defaultCurrency }}</p>
                  </div>
                </template>
                <el-option
                  :label="entityStore.entityDetail.defaultCurrency"
                  :value="entityStore.entityDetail.defaultCurrency"
                >
                </el-option>
              </el-select>

              <el-select
                v-else
                :model-value="entryLineList?.[item.index]?.currency"
                popper-class="elv-automation-rule-entry-auxiliary-item-popper is-not__line"
                @change="onChangeSelect('currency', item.index, $event)"
              >
                <template #label="{ label }">
                  <span>{{ label }}</span>
                </template>
                <el-option
                  v-for="currency in currencyOptions"
                  :key="currency.value"
                  :label="currency.label"
                  :value="currency.value"
                >
                  {{ currency.title }}
                </el-option>
              </el-select>
            </div>

            <div
              v-if="
                entryLineList?.[item.index]?.amount !== undefined &&
                currentChartOfAccount(item.chartOfAccountId).value?.currencyTypes?.[0] !== 'REPORTING' &&
                !['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug)
              "
              class="elv-automation-rule-entry-auxiliary-item"
            >
              <p class="elv-automation-rule-entry-auxiliary-item__title">
                {{ t('common.amount') }}
              </p>
              <el-select
                :model-value="entryLineList?.[item.index]?.amount"
                popper-class="elv-automation-rule-entry-auxiliary-item-popper is-not__line"
                @change="onChangeSelect('amount', item.index, $event)"
              >
                <template #label="{ label }">
                  <span>{{ label }} </span>
                </template>
                <el-option
                  v-for="amount in amountOptions"
                  :key="amount.value"
                  :label="amount.label"
                  :value="amount.value"
                >
                  {{ amount.title }}
                </el-option>
              </el-select>
            </div>

            <div
              v-if="entryLineList?.[item.index]?.amountFC !== undefined"
              class="elv-automation-rule-entry-auxiliary-item"
            >
              <p class="elv-automation-rule-entry-auxiliary-item__title">
                {{ t('common.amount') }}({{ entityStore.entityDetail.defaultCurrency }})
              </p>
              <div class="elv-automation-rule-entry-item-cascader">
                <el-cascader
                  ref="cascaderAmountFCRefs"
                  popper-class="elv-automation-rule-entry-item-cascader-popper"
                  class="el-select is-filterable"
                  :show-all-levels="false"
                  :model-value="entryLineList?.[item.index].amountFC"
                  :options="amountFCOptions(item.index)"
                  :disabled="
                    props.entryLines?.[item.index]?.amountFC?.[0] === 'COST_BASIS_OF_SALES' ||
                    ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug) ||
                    (hasGstType && ['NA', ''].includes(entryLineList?.[item.index].gstType))
                  "
                  @change="onChangeSelect('amountFC', item.index, $event)"
                >
                  <template #default="{ data }">
                    <div
                      class="elv-automation-rule-entry-item-cascader-item"
                      :class="{
                        'is-formula': data.value === 'FORMULA',
                        'has-line': ['AMOUNT_FC', 'FEE_AMOUNT_FC'].includes(data.value)
                      }"
                      @click="onOpenFormula('amountFC', item.index, data)"
                    >
                      <SvgIcon v-if="data.value === 'FORMULA'" name="function-math" width="18px" height="18px" />
                      <img v-if="data?.logo" :src="data?.logo" :alt="data?.alt" />
                      <span>{{ data.label }}</span>
                    </div>
                  </template>
                </el-cascader>
                <!-- cascader placeholder -->
                <div
                  class="elv-automation-rule-entry-item-cascader__display"
                  :class="{
                    'is-disabled':
                      props.entryLines?.[item.index]?.amountFC?.[0] === 'COST_BASIS_OF_SALES' ||
                      ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug) ||
                      (hasGstType && ['NA', ''].includes(entryLineList?.[item.index].gstType))
                  }"
                >
                  <template v-if="entryLineList?.[item.index]?.amountFC?.length">
                    <p v-if="isNumber(entryLineList?.[item.index]?.amountFC?.[0])" class="cascader-placeholder__tag">
                      {{ `#${entryLineList?.[item.index]?.amountFC?.[0] + 1} Amount` }}({{
                        entityStore.entityDetail.defaultCurrency
                      }})
                    </p>

                    <div v-else-if="entryLineList?.[item.index]?.amountFC?.[0] === 'FORMULA'" class="is-formula">
                      <template
                        v-if="
                          entryLineList?.[item.index]?.amountFC?.[1] &&
                          formulaFormatter(entryLineList?.[item.index]?.amountFC?.[1]).length
                        "
                      >
                        <SvgIcon name="function-math" width="18px" height="18px" />
                        <template
                          v-for="(formula, i) in formulaFormatter(entryLineList?.[item.index]?.amountFC?.[1])"
                          :key="i"
                        >
                          <p v-if="formula?.type !== 'operator'" class="cascader-placeholder__tag">
                            {{ formula.value }}
                          </p>
                          <span v-else class="is-operator"> {{ formula.value }}</span>
                        </template>
                      </template>
                      <span v-else class="is-placeholder">Select...</span>
                    </div>
                    <p
                      v-else-if="entryLineList?.[item.index]?.amountFC?.[0] === 'COST_BASIS_OF_SALES'"
                      class="cascader-placeholder__tag"
                    >
                      COST_BASIS_OF_SALES
                    </p>
                    <p v-else class="cascader-placeholder__tag">
                      {{
                        find(amountFCOptions(item.index), { value: entryLineList?.[item.index]?.amountFC?.[0] })?.title
                      }}
                    </p>
                  </template>
                  <div
                    v-else-if="
                      ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug) ||
                      (hasGstType && ['NA', ''].includes(entryLineList?.[item.index].gstType))
                    "
                    class="is-formula"
                  >
                    <SvgIcon name="function-math" width="18px" height="18px" />
                    <p class="cascader-placeholder__tag">
                      {{
                        gstFormulaFormat(
                          hasGstType?.gstType,
                          hasGstTypeIndex,
                          ['gst_input', 'gst_output'].includes(
                            currentChartOfAccount(item.chartOfAccountId).value?.slug
                          ),
                          hasGstType?.gstRatio,
                          defaultCurrency
                        )
                      }}
                    </p>
                  </div>
                  <template v-else>
                    <span class="is-placeholder">Select...</span>
                  </template>
                </div>
              </div>
            </div>
          </template>

          <template v-if="!!props.entryLines?.[item.index]?.auxiliaryTypes?.length">
            <div
              v-for="(auxiliary, i) in props.entryLines?.[index]?.auxiliaryTypes"
              :key="i"
              class="elv-automation-rule-entry-auxiliary-item"
            >
              <template v-if="!isEmpty(entryLineAuxiliaryValueMap?.[index]?.[i]) && auxiliary.name">
                <p class="elv-automation-rule-entry-auxiliary-item__title">
                  {{ `${t('report.auxiliaryCode')} / ${auxiliary.name}` }}
                </p>
                <el-select
                  v-model="entryLineAuxiliaryValueMap[index][i].value.type"
                  popper-class="elv-automation-rule-entry-auxiliary-item-popper"
                >
                  <el-option
                    v-for="auxiliaryType in auxiliaryOptions(auxiliary)"
                    :key="auxiliaryType.value"
                    :label="auxiliaryType.label"
                    :value="auxiliaryType.value"
                  >
                    <div v-if="auxiliaryType?.info" class="elv-automation-auxiliary-dropdown__item-special">
                      <div>{{ auxiliaryType.label }}</div>
                      <p>{{ auxiliaryType.info }}</p>
                    </div>
                    <template v-else>
                      {{ auxiliaryType.label }}
                    </template>
                  </el-option>
                </el-select>
              </template>
            </div>
          </template>
        </div>

        <template
          v-if="
            !props.isGeneralJournalType &&
            item.gstType !== 'NA' &&
            item.gstType !== 'NONE' &&
            item.gstType !== '' &&
            entryLineList.find((i: any) => i.gstType !== 'NA')?.balanceType === item.balanceType &&
            (((item.index + 1) % 2 !== 0 && entryLines?.length !== 2) || entryLines?.length === 2)
          "
        >
          <div class="elv-automation-rule-entry-item__GSTRate">
            {{ t('title.GSTRate') }}: {{ transformI18n(find(journalTypeGstOptions, { value: item.gstType })?.label)
            }}<span v-if="item.gstType !== 'EXEMPT'"
              >, {{ item.gstType !== 'ZERO' ? `${Number(item.gstRatio)}%` : '0%' }}</span
            >
          </div>
        </template>
      </div>
    </template>

    <FormulaDialog
      ref="formulaDialogRef"
      :model="formulaItem?.value ? 'edit' : 'add'"
      :formula-data="formulaItem?.value || ''"
      :parameters="amountParameters(formulaItem?.index)"
      @onSaveFormula="onSaveFormula"
    />
  </div>
</template>

<script setup lang="ts">
import { transformI18n } from '@/i18n/index'
import { gstFormulaFormat } from '@/lib/utils'
import FormulaDialog from './FormulaDialog.vue'
import { useComputedHook } from '@/hooks/useComputedHook'
import { useEntityStore } from '@/stores/modules/entity'
import { find, isEmpty, isNumber, cloneDeep } from 'lodash-es'
import { journalTypeGstOptions } from '@/config/reports/index'

const props = defineProps({
  journalTypeData: {
    type: Object,
    required: true
  },
  entryLines: {
    type: Array<any>,
    required: true
  },
  auxiliaryTypeList: {
    type: Array<any>,
    required: true
  },
  loading: {
    type: Boolean,
    required: true
  },
  activeTab: {
    type: String,
    required: true
  },
  conditionType: {
    type: String,
    default: ''
  },
  isGeneralJournalType: {
    type: Boolean,
    required: true
  },
  amountFormulaList: {
    type: Array as () => any[],
    required: true
  }
})

const { t, locale } = useI18n()
const entryLineList: any = toRef(() => props.entryLines)
const entityStore = useEntityStore()

const entryLineAuxiliaryValueMap = defineModel<any>('entryLineAuxiliaryValueMap', { required: true })
const defaultCurrency = unref(entityStore.entityDetail.defaultCurrency)

const cascaderAmountFCRefs: any = ref([])
const formulaItem: any = ref({})
const formulaDialogRef = ref()
const marketActions = ['SPOT_BUY', 'SPOT_SELL', 'FOREIGN_EXCHANGE', 'SWAP']

const emit = defineEmits(['onChangeSelect'])

const auxiliaryOptions = computed(() => {
  return (auxiliary: any) => {
    const list: any = []
    const data: any = {
      value: auxiliary.type === 'ITEM' ? 'AUXILIARY_ITEM' : auxiliary.type
    }
    let from = 'transfer'
    let fromEnum = 'TRANSFER'
    if (props.activeTab === 'gainLoss') {
      from = 'gain(loss)'
      fromEnum = 'GAIN (LOSS)'
    } else if (props.activeTab === 'trade') {
      from = 'trade'
      fromEnum = 'TRADE'
    }
    switch (auxiliary.type) {
      case 'ENTITY_ACCOUNT':
        data.label = `${fromEnum}.ACCOUNT`
        data.info = `Treasury account for each ${from}`
        break
      case 'COUNTERPARTY':
        data.label = `${fromEnum}.COUNTERPARTY`
        data.info = `Counterparty for each ${from}`
        break
      default:
        data.label = `${fromEnum}.ACCOUNT.${auxiliary.name}`
        data.info = `${auxiliary.name} of account for each ${from}`
        break
    }
    if ((props.activeTab !== 'transfer' && auxiliary.type !== 'COUNTERPARTY') || props.activeTab === 'transfer') {
      list.push(data)
    }

    if (auxiliary.type === 'ITEM') {
      const auxiliaryType = find(props.auxiliaryTypeList, { auxiliaryTypeId: auxiliary.auxiliaryTypeId })
      auxiliaryType?.auxiliaryItems.forEach((item: any) => {
        list.push({
          label: item.value,
          value: item.auxiliaryItemId
        })
      })
    }

    if (auxiliary.type === 'COUNTERPARTY') {
      list.push({
        label: `${fromEnum}.ACCOUNT.${auxiliary.name}`,
        value: 'ENTITY_ACCOUNT_COUNTERPARTY',
        info: `Client of account for each ${from}`
      })
    }
    list.push({
      label: 'Not Set',
      value: 'NONE'
    })
    return list
  }
})

const currentChartOfAccount: any = useComputedHook((chartOfAccountId: string) => {
  return find(entityStore.chartOfAccountList, { chartOfAccountId })
})

const currencyOptions = computed(() => {
  if (props.activeTab === 'gainLoss') {
    return [
      {
        label: 'Gain (loss)_Asset',
        value: 'GAIN_LOSS_ASSET',
        title: 'Gain (Loss) Asset'
      }
    ]
  }
  if (props.activeTab === 'trade') {
    let list: any = []
    if (marketActions.includes(props.conditionType)) {
      list.push({
        label: 'Base_Asset',
        value: 'BASE_ASSET',
        title: 'Base Asset'
      })
    }
    list = list.concat([
      {
        label: 'Counter_Asset',
        value: 'COUNTER_ASSET',
        title: 'Counter Asset'
      },
      {
        label: 'Fee_Asset',
        value: 'FEE_ASSET',
        title: 'Fee Asset'
      }
    ])

    return list
  }
  return []
})

const amountOptions = computed(() => {
  if (props.activeTab === 'gainLoss') {
    return [
      {
        label: 'Gain (loss)_Amount',
        value: 'GAIN_LOSS_AMOUNT',
        title: 'Gain (Loss) Amount'
      }
    ]
  }
  if (props.activeTab === 'trade') {
    let list: any = []
    if (marketActions.includes(props.conditionType)) {
      list.push({
        label: 'Base_Amount',
        value: 'BASE_AMOUNT',
        title: 'Base Amount'
      })
    }
    list = list.concat([
      {
        label: 'Counter_Amount',
        value: 'COUNTER_AMOUNT',
        title: 'Counter Amount'
      },
      {
        label: 'Fee_Amount',
        value: 'FEE_AMOUNT',
        title: 'Fee Amount'
      }
    ])

    return list
  }
  return []
})

const amountParameters = computed(() => {
  return (index: string | number = '') => {
    let list = cloneDeep(props.amountFormulaList).filter((item: any) => item.type !== 'COST_BASIS_OF_SALES')
    if (index !== '') {
      list = list.filter(
        (item: any) => (item.type === 'ENTRY_INDEX' && item.value !== index) || item.type !== 'ENTRY_INDEX'
      )
    }
    if (!marketActions.includes(props.conditionType)) {
      list = list.filter((item: any) => item.type !== 'BASE_AMOUNT_FC')
    }
    return list
  }
})

const amountFCOptions: any = computed(() => {
  return (i: number) => {
    let options: any = []
    if (props.activeTab === 'gainLoss') {
      options.push({
        label: `Amount (${defaultCurrency})`,
        title: `Amount (${defaultCurrency})`,
        value: 'AMOUNT_FC'
      })
    }
    if (props.activeTab === 'trade') {
      if (marketActions.includes(props.conditionType)) {
        options.push({
          label: `Base Amount (${defaultCurrency})`,
          title: `Base_Amount (${defaultCurrency})`,
          value: 'BASE_AMOUNT_FC'
        })
      }
      options = options.concat([
        {
          label: `Counter Amount (${defaultCurrency})`,
          title: `Counter_Amount (${defaultCurrency})`,
          value: 'COUNTER_AMOUNT_FC'
        },
        {
          label: `Fee Amount (${defaultCurrency})`,
          title: `Fee_Amount (${defaultCurrency})`,
          value: 'FEE_AMOUNT_FC'
        }
      ])
    }
    props.entryLines.forEach((entryLine: any, index: number) => {
      if (index !== i) {
        options.push({
          label: `#${index + 1} Amount(${defaultCurrency})`,
          title: `#${index + 1} Amount(${defaultCurrency})`,
          value: index
        })
      }
    })
    if (props.journalTypeData?.brokerInventoryType === 'SALE') {
      options.push({
        label: `Cost Basis of Sales`,
        title: `Cost COST_BASIS_OF_SALES of Sales`,
        value: 'COST_BASIS_OF_SALES'
      })
    }

    options.push({
      label: `Formula`,
      value: 'FORMULA'
    })
    return options
  }
})

const hasGstTypeIndex = computed(() => {
  return entryLineList.value.findIndex((i: any) => i.gstType !== 'NONE' && i.gstType !== 'NA' && i.gstType !== '')
})

const hasGstType = computed(() => {
  return entryLineList.value[hasGstTypeIndex.value]
})

const formulaFormatter = computed(() => {
  return (formula: string) => {
    const operators = ['+', '-', '*', '/', '(', ')']
    let list: { value: string; type: 'operator' | 'non-operator' | 'number' }[] = []
    let buffer = '' // 用于暂存连续的非操作符字符
    const formulaIsNumber = (char: string) => char >= '0' && char <= '9'
    for (let i = 0; i < formula.length; i += 1) {
      if (operators.includes(formula[i])) {
        if (buffer.length > 0) {
          const info = formulaIsNumber(buffer) ? 'number' : 'non-operator'
          list.push({ value: buffer, type: info }) // 将暂存的非操作符字符作为一个整体推入数组
          buffer = '' // 重置 buffer
        }
        list.push({ value: formula[i], type: 'operator' }) // 如果当前字符是操作符，直接推入数组
      } else {
        buffer += formula[i] // 将非操作符字符添加到 buffer
      }
    }

    if (buffer.length > 0) {
      // 修改：循环结束后，使用 isNumber 判断 buffer 是否为数字
      const info = formulaIsNumber(buffer) ? 'number' : 'non-operator'
      list.push({ value: buffer, type: info }) // 将最后的非操作符字符作为一个整体推入数组
    }
    list = list.map((item) => {
      if (item.type === 'non-operator') {
        const parameter = props.amountFormulaList.find((val: any) => val.var === item.value)
        return { ...item, value: parameter ? parameter.label : item.value }
      }
      return item
    })
    return list
  }
})

const onOpenFormula = (key: string, index: number, data: any) => {
  if (data?.value === 'FORMULA') {
    if (key === 'amountFC') {
      cascaderAmountFCRefs.value?.[index]?.togglePopperVisible()
    }
    formulaItem.value.key = key
    formulaItem.value.index = index
    formulaItem.value.value =
      entryLineList.value?.[index]?.[key]?.length > 1 && !isNumber(entryLineList.value?.[index]?.[key]?.[1])
        ? entryLineList.value?.[index]?.[key]?.[1] || ''
        : ''
    formulaDialogRef.value?.onCheckFormulaDialog()
  }
}

const onChangeSelect = (key: string, index: number, value: any) => {
  let data: any = value
  console.log(value)
  if (key === 'amountFC' && data === undefined) {
    data = ['FORMULA', entryLineList.value?.[index]?.amountFC?.[1] ?? '']
  }
  emit('onChangeSelect', key, index, data)
}

const onSaveFormula = (formula: string) => {
  if (formulaItem.value) {
    emit('onChangeSelect', formulaItem.value.key, formulaItem.value.index, ['FORMULA', formula])
  }
}

defineExpose({ formulaFormatter })
</script>

<style lang="scss">
.elv-automation-rule-action-entry {
  width: 100%;
  max-width: 802px;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .elv-automation-rule-entry-item {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: #f9fafb;

    &.is-Cr {
      background: #fffaf9;
    }

    &.is-Dr {
      background: #f5f8ff;
    }

    .elv-automation-rule-entry-reporting-currency {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        display: block;
        border-radius: 50%;
        margin-right: 10px;
      }

      p {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }

    .elv-automation-rule-entry-title {
      display: flex;
      align-items: center;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      span {
        padding: 2px 4px;
        font-family: 'Barlow';
        font-weight: 500;
        border-radius: 2px;
        background: #feede9;
        color: #f24923;
        margin-right: 10px;

        &.is-Dr {
          background: #e2eafc;
          color: #2368f2;
        }
      }

      p {
        display: flex;
        min-width: 20px;
        height: 21px;
        padding: 2px 4px;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        background: #edf0f3;
        color: #1e2024;
        font-family: 'Barlow';
        font-weight: 500;
        margin-right: 10px;
      }
    }

    .elv-automation-rule-entry-auxiliary-list {
      width: 100%;
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid #edf0f3;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;

      .elv-automation-rule-entry-auxiliary-item {
        width: 256.66px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 8px;

        .elv-automation-rule-entry-auxiliary-item__title {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .elv-automation-rule-entry-item-cascader {
          position: relative;
          height: fit-content;

          .el-cascader.el-select .el-input {
            width: 256.66px;
            height: 44px;
          }

          .elv-automation-rule-entry-item-cascader__display {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 12px;
            width: 208px;
            height: 30px;
            background: #fff;
            z-index: 10;
            pointer-events: none;

            .is-placeholder {
              color: #838d95;
              font-family: 'Plus Jakarta Sans';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px;
            }

            .cascader-placeholder__with-icon {
              display: flex;
              align-items: center;
              color: #0e0f11;
              font-family: 'Plus Jakarta Sans';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px;
              max-width: 208px;
              white-space: nowrap;
              overflow: hidden;

              img {
                width: 20px;
                height: 20px;
                display: block;
                border-radius: 50%;
                margin-right: 10px;
              }

              span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                min-width: 0; /* 确保flex容器内文本不撑破容器 */
                flex-shrink: 1; /* 允许文本缩小以适应容器 */
              }
            }

            .is-formula {
              display: flex;
              align-items: center;

              svg {
                margin-right: 2px;
              }

              .is-operator {
                color: #1e2024;
                font-family: 'Barlow';
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0 6px;
              }
            }

            .cascader-placeholder__tag {
              display: inline-block;
              width: fit-content;
              padding: 0px 8px;
              border-radius: 3px;
              border: 1px solid #dde1e6;
              background: #edf0f3;
              color: #1753eb;
              font-family: 'Barlow';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px;
              max-width: 208px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &.is-disabled {
              background-color: #f9fafb;
            }
          }
        }

        .el-select__selected-item.el-select__placeholder:not(.is-transparent) {
          span {
            color: #1753eb;
            font-family: 'Barlow';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            display: flex;
            padding: 0px 8px;
            justify-content: center;
            align-items: center;
            gap: 2px;
            border-radius: 3px;
            border: 1px solid #dde1e6;
            background: #edf0f3;
            width: fit-content;
          }
        }
      }
    }
  }
}

.el-popper.el-select__popper.elv-automation-rule-entry-auxiliary-item-popper {
  &:not(.is-not__line) {
    .el-select-dropdown__item {
      &:last-of-type {
        border-top: 1px solid #edf0f3;
      }

      &:nth-of-type(2) {
        border-top: 1px solid #edf0f3;

        &:has(.elv-automation-auxiliary-dropdown__item-special) {
          border-top: 0px;
        }
      }
    }
  }

  .el-select-dropdown__item {
    min-height: 32px;
    height: fit-content;

    .elv-automation-auxiliary-dropdown__item-special {
      display: flex;
      flex-direction: column;
      padding: 8px 0;

      > div {
        display: flex;
        padding: 0px 8px;
        width: fit-content;
        justify-content: center;
        align-items: center;
        gap: 2px;
        border-radius: 3px;
        border: 1px solid #dde1e6;
        background: #edf0f3;
        color: #1753eb;
        font-family: 'Barlow';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 4px;
      }

      p {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.el-popper.elv-automation-rule-entry-item-cascader-popper {
  transform: translateY(-11.5px);
  box-shadow: none;
  background-color: transparent;
  border: 0px;

  .el-cascader-panel {
    padding: 1px;
    border-radius: 6px;
  }

  .el-scrollbar.el-cascader-menu {
    border-radius: 6px;
    background: #fff;

    box-shadow:
      0px 2px 6px 0px rgba(0, 0, 0, 0.05),
      0px 0px 1px 0px rgba(0, 0, 0, 0.3);
    height: fit-content;
    position: relative;

    &:first-of-type {
      .el-cascader-menu__wrap.el-scrollbar__wrap {
        height: fit-content;
      }
    }

    &:last-of-type {
      z-index: 10;
    }
  }

  &.is-filterable {
    .el-scrollbar.el-cascader-menu:nth-of-type(2) .el-cascader-menu__list {
      padding-top: 41px;
    }
  }

  .el-cascader-menu__list {
    padding: 0px;
    border-radius: 6px;
    height: fit-content;
    position: relative;

    .el-cascader-node {
      display: flex;
      min-height: 32px;
      height: fit-content;
      box-sizing: border-box;
      align-items: center;
      gap: 8px;
      padding: 0px;
      align-self: stretch;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.is-disabled {
        position: fixed;
        top: 0px;
        background-color: #fff;
        z-index: 10;
      }

      &.in-active-path {
        background-color: #f9fafb;
      }

      .el-cascader-node__label {
        display: flex;
        align-items: center;
        padding: 0px;
        width: 100%;
        min-height: 32px;
        height: fit-content;

        img {
          width: 18px;
          height: 18px;
          display: block;
          border-radius: 50%;
          margin-right: 8px;
        }

        .elv-automation-rule-entry-item-cascader-item {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding: 0px 8px;
          display: flex;
          align-items: center;

          &.is-formula {
            width: 100%;
            height: 32px;
            border-top: 1px solid #edf0f3;
            color: #1753eb;
          }

          &.has-line {
            width: 100%;
            height: 32px;
            border-bottom: 1px solid #edf0f3;
          }

          p {
            display: flex;
            padding: 6px 8px;
            height: 19px;
            box-sizing: border-box;
            align-items: center;
            color: #fb212f;
            leading-trim: both;
            text-edge: cap;
            font-family: 'Barlow';
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-radius: 14px;
            background: #edf0f3;
            margin-left: 8px;
          }
        }

        .elv-automation-rule-entry-item-cascader-search.el-input {
          height: 40px;
          border-bottom: 1px solid #edf0f3;

          .el-input__wrapper {
            padding: 1px 8px;
            border: none !important;
            box-shadow: none !important;
          }
        }
      }

      &.is-active {
        color: #1753eb;
      }
    }

    .el-icon.el-cascader-node__prefix {
      display: none;
    }
  }
}
</style>
