<template>
  <div class="elv-transactions-automation-table-draggable">
    <div v-if="!props.row?.systemAutomationRuleId">
      <SvgIcon width="20" height="20" name="draggable" class="elv-transactions-automation-table-draggable__icon" />
    </div>
    <span :class="{ 'has-padding': !props.row?.systemAutomationRuleId }">{{ props.row.priority }}</span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  row: {
    type: Object,
    required: true
  }
})
</script>

<style lang="scss" scoped>
.elv-transactions-automation-table-draggable {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    width: 35px;
    height: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    cursor: move;
    fill: #dde1e6;

    &:hover {
      fill: #4674eb;
    }
  }

  span {
    width: 50px;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #aaafb6;
    white-space: nowrap;
    padding-left: 10px;

    &.has-padding {
      padding: 0 10px;
    }
  }
}
</style>
