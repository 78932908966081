<template>
  <el-select
    v-model="operator"
    popper-class="elv-automation-rule-condition-input-popper"
    class="elv-automation-rule-condition-short-input"
    placeholder=" "
    filterable
    @change="() => emit('onChangeType')"
  >
    <el-option v-for="item in memoOption" :key="item.value" :label="transformI18n(item.label)" :value="item.value" />
  </el-select>

  <el-input
    v-model="value"
    :class="[
      operator === 'BETWEEN'
        ? 'elv-automation-rule-condition-shorter-input'
        : 'elv-automation-rule-condition-short-input'
    ]"
    @change="() => emit('onChangeCondition')"
  />
</template>

<script setup lang="ts">
import { $t, transformI18n } from '@/i18n/index'

const value = defineModel<any>('value', { required: true })
const operator = defineModel<any>('operator', { required: true })

const emit = defineEmits(['onChangeType', 'onChangeCondition'])

const memoOption = reactive([
  { label: $t('common.equals'), value: 'EQ' },
  { label: $t('common.includes'), value: 'INCLUDES' }
])
</script>

<style lang="scss" scoped></style>
