<template>
  <el-dialog
    v-model="showFormulaDialog"
    width="640px"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-formula-editor-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-formula-editor-dialog-header__title">Formula</h4>
    </template>
    <div>
      <el-dropdown trigger="click" max-height="200px" popper-class="elv-formula-editor-popper" @command="addParameter">
        <div class="elv-formula-editor-add">
          <SvgIcon name="add-default" width="16" height="16" fill="#1753eb" />Add Parameter
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="(item, index) in props.parameters" :key="index" :command="item">{{
              item.label
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div ref="quillEditorRef" class="elv-formula-editor-content"></div>
      <p class="elv-formula-editor-tips">Use + - * / ( ) between parameters</p>
    </div>

    <template #footer>
      <el-button
        type="primary"
        round
        class="elv-formula-editor-dialog-footer__save"
        :loading="submitLoading"
        :disabled="disabledSave"
        @click="onSaveFormula"
        >{{ t('button.save') }}</el-button
      >
    </template>
  </el-dialog>
</template>
<script lang="ts" setup>
import Quill from 'quill'
import { Parser } from 'expr-eval'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  parameters: {
    type: Array as () => any[],
    default: () => []
  },
  formulaData: {
    type: String,
    default: ''
  }
})

const showFormulaDialog = ref(false)
const formulaVal = ref('')
const BlockEmbed: any = Quill.import('blots/block/embed') // 使用 BlockEmbed 来确保块级结构

const emit = defineEmits(['onSaveFormula'])

class CustomParameterBlot extends BlockEmbed {
  static create(value: any) {
    const node = super.create()
    node.setAttribute('data-value', value)
    node.innerHTML = props.parameters.find((val) => val.var === value)?.label
    node.setAttribute('contenteditable', false) // 防止编辑
    return node
  }

  static value(node: any) {
    return node.getAttribute('data-value')
  }
}

CustomParameterBlot.blotName = 'custom-parameter'
CustomParameterBlot.tagName = 'div'
CustomParameterBlot.className = 'custom-parameter-blot'

Quill.register(CustomParameterBlot)

const { t } = useI18n()
const submitLoading = ref(false)
const showDropdown = ref(false)
const beforeComposingContent: any = ref('')
let quill: any
const quillEditorRef: any = ref(null)

const operators = ['+', '-', '*', '/', '(', ')']

const isValidFormula = (formula: any, variables: any) => {
  const parser = new Parser()
  try {
    // 尝试解析公式
    const parsed = parser.parse(formula)
    // 获取公式中的所有变量
    const formulaVariables = parsed.variables()
    // 检查公式中的变量是否都在变量名称列表中
    for (const variable of formulaVariables) {
      if (!variables.includes(variable)) {
        return false
      }
    }
    return true
  } catch (error) {
    return false
  }
}

const disabledSave = computed(() => {
  console.log(formulaVal.value, 'formula')
  if (!formulaVal.value) return true
  return !isValidFormula(
    formulaVal.value,
    props.parameters.map((item) => item.var)
  )
})
// eslint-disable-next-line no-unused-vars
const removeLastTag = () => {
  const range = quill.getSelection()
  console.log(range, 'range')
  if (range && range.index > 0) {
    const [blot] = quill.getLeaf(range.index - 1)
    if (blot && blot.statics && blot.statics.blotName === 'custom-parameter') {
      quill.deleteText(range.index - 1, 1)
    }
  } else {
    quill.deleteText(range.index - 1, 1)
  }
}

const onCloseDialog = () => {
  if (quill) {
    quill.off('text-change')
    quill = null
    quillEditorRef.value.innerHTML = ''
    console.log(123, quill)
  }
}
// 切换弹窗展示
const onCheckFormulaDialog = () => {
  showFormulaDialog.value = !showFormulaDialog.value
}

const updateFormula = () => {
  const content = quill?.getContents()
  // console.log(content.ops, 'content.ops')
  const formula = content.ops
    .map((op: any) => {
      if (op.insert) {
        if (op.insert['custom-parameter']) {
          return op.insert['custom-parameter']
        }
        if (op.insert === '\n') {
          return ''
        }
        // 返回op.insert并去除'\n'
        return op.insert.replace(/\n/g, '')
      }
      return ''
    })
    .join('')
  formulaVal.value = formula
}

const addParameter = (command: any) => {
  quill.focus()
  const range = quill.getSelection()
  if (range && range.index !== null) {
    quill.insertEmbed(range.index, 'custom-parameter', command.var)
    quill.setSelection(range.index + 1)
  }
  showDropdown.value = false
  // 焦点定位到最后
  quill?.setSelection(quill.getLength())
  updateFormula()
}

const onSaveFormula = () => {
  updateFormula()
  if (!formulaVal.value) {
    return
  }
  emit('onSaveFormula', formulaVal.value)
  onCloseDialog()
  onCheckFormulaDialog()
}

defineExpose({ onCheckFormulaDialog })

watch(
  () => showFormulaDialog.value,
  () => {
    if (showFormulaDialog.value) {
      nextTick(() => {
        quill = new Quill(quillEditorRef.value, {
          theme: 'snow',
          modules: {
            toolbar: false // 禁用工具栏
          }
        })
        quill.on('text-change', (delta: any, oldDelta: any, source: any) => {
          if (source === 'user') {
            const currentIndex = quill.getSelection().index
            const currentText = quill.getText(0, currentIndex)
            const firstChar = quill.getText()[0]
            const lastChar = currentText.slice(-1)
            const symbolRegex = /[^\p{L}\p{N}\p{Zs}]/u

            if (
              (!Number.isNaN(Number(firstChar)) && currentIndex === 0) ||
              (!Number.isNaN(Number(lastChar)) && !symbolRegex.test(lastChar) && currentIndex !== 0)
            ) {
              updateFormula()
              return
            }
            if (!operators.includes(firstChar) && !/\s/.test(firstChar) && currentIndex === 0) {
              quill.deleteText(currentText.length, 1)
              return
            }
            if (!operators.includes(lastChar) && !/\s/.test(lastChar)) {
              quill.deleteText(currentText.length - 1, 1)
              return
            }
            const content = delta.ops?.[delta.ops.length - 1]?.insert
            const regex = /^[a-z]+$/i
            if (!operators.includes(content) && (symbolRegex.test(content) || regex.test(content))) {
              quill.deleteText(currentText.length + 1, 1)
            }

            updateFormula()
          }
        })

        const input = quillEditorRef.value.querySelector('.ql-editor')

        input.addEventListener('compositionstart', () => {
          const content = quill.getContents()
          beforeComposingContent.value = content
        })

        input.addEventListener('compositionend', () => {
          if (beforeComposingContent.value !== quill.getContents()) {
            quill?.setContents(beforeComposingContent.value)
            beforeComposingContent.value = ''
            // 焦点定位到最后
            quill?.setSelection(quill.getLength())
          }
        })

        // quill.keyboard.addBinding({ key: 8 }, removeLastTag)
        // quill.keyboard.addBinding({ key: 'Backspace' }, removeLastTag)

        // 获取焦点
        console.log(quill?.focus, 'quill')
        if (quill?.focus) {
          quill?.focus()
        }

        if (props.model === 'edit' && props.formulaData) {
          let buffer = '' // 用于暂存连续的非操作符字符
          const formulaData: any[] = [] // 用于存储分割后的字符
          for (let i = 0; i < props.formulaData.length; i += 1) {
            if (operators.includes(props.formulaData[i])) {
              if (buffer.length > 0) {
                formulaData.push(buffer) // 将暂存的非操作符字符作为一个整体推入数组
                buffer = '' // 重置 buffer
              }
              formulaData.push(props.formulaData[i]) // 如果当前字符是操作符，直接推入数组
            } else {
              buffer += props.formulaData[i] // 将非操作符字符添加到 buffer
            }
          }
          if (buffer.length > 0) {
            formulaData.push(buffer) // 循环结束后，将最后的非操作符字符作为一个整体推入数组
          }
          const storedDelta = formulaData.map((item: any) => {
            const formulaIsNumber = (char: string) => char >= '0' && char <= '9'
            if (operators.includes(item) || formulaIsNumber(item)) {
              return { insert: `${item}\n` }
            }
            return { insert: { 'custom-parameter': item } }
          })
          storedDelta.push({ insert: '\n' })
          nextTick(() => {
            quill?.setContents(storedDelta)
          })
        }
      })
    } else {
      quill = null
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.elv-formula-editor-content {
  width: 572px;
  height: 80px;
  border-radius: 4px;
  border: 1px solid #dde1e6;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);

  :deep(.ql-editor) {
    padding: 10px 12px;
    color: #1e2024;
    font-family: 'Barlow';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;

    .custom-parameter-blot {
      width: fit-content;
      display: flex;
      height: 20px;
      padding: 2px 6px 2px 4px;
      align-items: center;
      box-sizing: border-box;
      border-radius: 2px;
      border: 1px solid #dde1e6;
      background: #eef4fb;
      color: #1e2024;
      font-family: 'Barlow';
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.22px;
      margin-right: 6px;
    }

    p {
      line-height: 20px;
    }
  }
}

.elv-formula-editor-add {
  display: flex;
  align-items: center;
  color: #1753eb;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
  position: relative;
  right: 0px;

  &:not(.is-disabled):hover {
    cursor: pointer;
  }

  .is-disabled {
    cursor: not-allowed;
  }

  svg {
    margin-right: 4px;
  }
}

.elv-formula-editor-tips {
  color: #838d95;
  font-family: 'Plus Jakarta Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}
</style>

<style lang="scss">
.elv-formula-editor-dialog {
  width: 640px;
  min-height: 287px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-formula-editor-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 34px 26px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .el-dropdown {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 20px;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;

      &.is-disabled {
        background: #edf0f3;
        color: #838d95;
      }
    }
  }
}

.elv-formula-editor-popper.el-popper {
  .el-dropdown-menu {
    padding: 0px;
  }

  .el-dropdown-menu__item {
    display: flex;
    height: 32px;
    padding: 0px 8px;
    align-items: center;
    align-self: stretch;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:not(.is-disabled):hover {
      background-color: #f9fafb;
    }
  }
}
</style>
