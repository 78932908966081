<template>
  <div class="elv-automation-rule-entryLines">
    <template v-if="!!entryLineList.length">
      <div
        v-for="item in entryLineList"
        :key="item.index"
        class="elv-automation-rule-entry-item"
        :class="[item.balanceType === 'Dr' ? 'is-Dr' : 'is-Cr']"
      >
        <!-- Special Journal-->
        <template v-if="!props.isGeneralJournalType">
          <div class="elv-automation-rule-entry-title">
            <p>#{{ item.index + 1 }}</p>
            <span :class="{ 'is-Dr': item.balanceType === 'Dr' }">{{ item.balanceType }}</span
            >{{
              item.chartOfAccountCalculator === 'TREASURY_ACCOUNT'
                ? `{ ${t('report.treasuryAccount')} }`
                : locale === 'en'
                  ? item?.chartOfAccount?.name
                  : item?.chartOfAccount?.nameCN || item?.chartOfAccount?.name
            }}
          </div>
        </template>
        <!-- General Journal -->
        <div v-else class="elv-automation-rule-entry-general-journal">
          <el-select v-model="item.balanceType" class="elv-automation-rule-entry-general-journal__balance">
            <el-option label="Dr" value="Dr" />
            <el-option label="Cr" value="Cr" />
          </el-select>
          <el-form-item label="">
            <ChartOfAccountOverlayDropdown
              v-model="item.chartOfAccountId"
              @onChangeChartOfAccount="onChangeChartOfAccount(item.index)"
            />
          </el-form-item>
          <SvgIcon
            name="sources-delete"
            width="16"
            height="16"
            fill="#AAAFB6"
            class="elv-automation-rule-entry-item__delete"
            @click="onDeleteEntry(item.index)"
          />
        </div>

        <div v-if="!props.loading && !!entryLineList.length" class="elv-automation-rule-entry-item-select__list">
          <template v-if="!!entryLineList?.[item.index]?.currency">
            <div class="elv-automation-rule-entry-item-select__item">
              <p class="elv-automation-rule-entry-item-select__item-title">
                {{ t('common.currency') }}
              </p>
              <div class="elv-automation-rule-entry-item-cascader">
                <el-cascader
                  popper-class="elv-automation-rule-entry-item-cascader-popper is-filterable"
                  class="el-select is-filterable"
                  :show-all-levels="false"
                  :disabled="
                    currentChartOfAccount(item.chartOfAccountId).value?.currencyTypes?.[0] === 'REPORTING' ||
                    ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug)
                  "
                  :model-value="entryLineList?.[item.index].currency"
                  :options="currencyOptions(item.chartOfAccountId).value"
                  @change="onChangeSelect('currency', item.index, $event)"
                >
                  <template #default="{ node, data }">
                    <el-input
                      v-if="node.isLeaf && data?.disabled"
                      ref="cascaderCurrencyInputRefs"
                      v-model="searchQuery"
                      placeholder="Search"
                      class="elv-automation-rule-entry-item-cascader-search"
                      @input="remoteMethod(data.type, item.index, $event)"
                    >
                      <template #prefix><SvgIcon name="select-search" width="18px" height="18px" /></template>
                      <template #suffix>
                        <el-icon v-show="searchLoading" class="is-loading"> <Loading /></el-icon>
                      </template>
                    </el-input>
                    <div v-else class="elv-automation-rule-entry-item-cascader-item">
                      <img v-if="data?.logo" :src="data?.logo" :alt="data?.alt" />
                      <span>{{ data.label }}</span>
                    </div>
                  </template>
                </el-cascader>

                <!-- cascader placeholder -->
                <div
                  class="elv-automation-rule-entry-item-cascader__display"
                  :class="{
                    'is-disabled':
                      currentChartOfAccount(item.chartOfAccountId).value?.currencyTypes?.[0] === 'REPORTING' ||
                      ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug)
                  }"
                >
                  <template v-if="entryLineList?.[item.index]?.currency?.length">
                    <p
                      v-if="entryLineList?.[item.index]?.currency?.[0] === 'REPORTING_CURRENCY'"
                      class="cascader-placeholder__with-icon"
                    >
                      <img
                        :src="entityStore.entityDetail.underlyingCurrency?.logo"
                        :alt="entityStore.entityDetail.underlyingCurrency?.name"
                      /><span>{{ entityStore.entityDetail.underlyingCurrency?.showSymbol }}</span>
                    </p>
                    <p
                      v-else-if="entryLineList?.[item.index]?.currency?.[0] === 'DIRECT_CURRENCY'"
                      class="cascader-placeholder__with-icon"
                    >
                      <img
                        :src="currentCurrency(entryLineList?.[item.index]?.currency?.[1]).value?.logo"
                        :alt="currentCurrency(entryLineList?.[item.index]?.currency?.[1]).value?.name"
                      />
                      <span>{{ currentCurrency(entryLineList?.[item.index]?.currency?.[1]).value?.showSymbol }}</span>
                    </p>
                    <p
                      v-else-if="entryLineList?.[item.index]?.currency?.[0] === 'BUSINESS_CURRENCY'"
                      class="cascader-placeholder__tag"
                    >
                      {{ props.currentBusinessData.columns?.[entryLineList?.[item.index]?.currency?.[1]] }}
                    </p>
                  </template>
                  <template v-else>
                    <span class="is-placeholder">Select...</span>
                  </template>
                </div>
              </div>
            </div>

            <div
              v-if="
                currentChartOfAccount(item.chartOfAccountId).value?.currencyTypes?.[0] !== 'REPORTING' &&
                !['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug)
              "
              class="elv-automation-rule-entry-item-select__item"
            >
              <p class="elv-automation-rule-entry-item-select__item-title">
                {{ t('common.amount') }}
              </p>
              <div class="elv-automation-rule-entry-item-cascader">
                <el-cascader
                  ref="cascaderAmountRefs"
                  popper-class="elv-automation-rule-entry-item-cascader-popper"
                  class="el-select is-filterable"
                  :show-all-levels="false"
                  :model-value="props.entryLines?.[item.index].amount"
                  :options="amountOptions"
                  :disabled="!item.chartOfAccountId"
                  @change="onChangeSelect('amount', item.index, $event)"
                >
                  <template #default="{ node, data }">
                    <el-input
                      v-if="node.isLeaf && data?.disabled"
                      v-model="searchQuery"
                      placeholder="Search"
                      class="elv-automation-rule-entry-item-cascader-search"
                      @input="remoteMethod(data.type, item.index, $event)"
                    >
                      <template #prefix><SvgIcon name="select-search" width="18px" height="18px" /></template>
                      <template #suffix>
                        <el-icon v-show="searchLoading" class="is-loading"> <Loading /></el-icon>
                      </template>
                    </el-input>
                    <div
                      v-else
                      class="elv-automation-rule-entry-item-cascader-item"
                      :class="{ 'is-formula': data.value === 'FORMULA' }"
                      @click="onOpenFormula('amount', item.index, data)"
                    >
                      <SvgIcon v-if="data.value === 'FORMULA'" name="function-math" width="18px" height="18px" />
                      <img v-if="data?.logo" :src="data?.logo" :alt="data?.alt" />
                      <span>{{ data.label }}</span>
                    </div>
                  </template>
                </el-cascader>

                <!-- cascader placeholder -->
                <div
                  class="elv-automation-rule-entry-item-cascader__display"
                  :class="{
                    'is-disabled': !item.chartOfAccountId
                  }"
                >
                  <template v-if="entryLineList?.[item.index]?.amount?.length">
                    <p
                      v-if="entryLineList?.[item.index]?.amount?.[0] === 'BUSINESS_FIELD'"
                      class="cascader-placeholder__tag"
                    >
                      {{ props.currentBusinessData.columns?.[entryLineList?.[item.index]?.amount?.[1]] }}
                    </p>
                    <div v-else-if="entryLineList?.[item.index]?.amount?.[0] === 'FORMULA'" class="is-formula">
                      <template
                        v-if="
                          entryLineList?.[item.index]?.amount?.[1] &&
                          formulaFormatter(entryLineList?.[item.index]?.amount?.[1]).length
                        "
                      >
                        <SvgIcon name="function-math" width="18px" height="18px" />
                        <template
                          v-for="(formula, i) in formulaFormatter(entryLineList?.[item.index]?.amount?.[1])"
                          :key="i"
                        >
                          <p v-if="formula?.type !== 'operator'" class="cascader-placeholder__tag">
                            {{ formula.value }}
                          </p>
                          <span v-else class="is-operator"> {{ formula.value }}</span>
                        </template>
                      </template>
                      <span v-else class="is-placeholder">Select...</span>
                    </div>
                  </template>
                  <template v-else>
                    <span class="is-placeholder">Select...</span>
                  </template>
                </div>
              </div>
            </div>

            <div class="elv-automation-rule-entry-item-select__item">
              <p class="elv-automation-rule-entry-item-select__item-title">
                {{ t('common.amount') }}({{ entityStore.entityDetail.defaultCurrency }})
              </p>
              <div class="elv-automation-rule-entry-item-cascader">
                <el-cascader
                  ref="cascaderAmountFCRefs"
                  popper-class="elv-automation-rule-entry-item-cascader-popper"
                  class="el-select is-filterable"
                  :show-all-levels="false"
                  :model-value="props.entryLines?.[item.index].amountFC"
                  :disabled="
                    props.entryLines?.[item.index]?.amountFC?.[0] === 'COST_BASIS_OF_SALES' ||
                    ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug) ||
                    (hasGstType && ['NA', ''].includes(entryLineList?.[item.index].gstType))
                  "
                  :options="amountFCOptions(item.index)"
                  @change="onChangeSelect('amountFC', item.index, $event)"
                >
                  <template #default="{ node, data }">
                    <el-input
                      v-if="node.isLeaf && data?.disabled"
                      v-model="searchQuery"
                      placeholder="Search"
                      class="elv-automation-rule-entry-item-cascader-search"
                      @input="remoteMethod(data.type, item.index, $event)"
                    >
                      <template #prefix><SvgIcon name="select-search" width="18px" height="18px" /></template>
                      <template #suffix>
                        <el-icon v-show="searchLoading" class="is-loading"> <Loading /></el-icon>
                      </template>
                    </el-input>
                    <div
                      v-else
                      class="elv-automation-rule-entry-item-cascader-item"
                      :class="{ 'is-formula': data.value === 'FORMULA' }"
                      @click="onOpenFormula('amountFC', item.index, data)"
                    >
                      <SvgIcon v-if="data.value === 'FORMULA'" name="function-math" width="18px" height="18px" />
                      <img v-if="data?.logo" :src="data?.logo" :alt="data?.alt" />
                      <span>{{ data.label }}</span>
                    </div>
                  </template>
                </el-cascader>
                <!-- cascader placeholder -->
                <div
                  class="elv-automation-rule-entry-item-cascader__display"
                  :class="{
                    'is-disabled':
                      props.entryLines?.[item.index]?.amountFC?.[0] === 'COST_BASIS_OF_SALES' ||
                      ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug) ||
                      (hasGstType && ['NA', ''].includes(entryLineList?.[item.index].gstType))
                  }"
                >
                  <template v-if="entryLineList?.[item.index]?.amountFC?.length">
                    <p v-if="isNumber(entryLineList?.[item.index]?.amountFC?.[0])" class="cascader-placeholder__tag">
                      {{ `E${entryLineList?.[item.index]?.amountFC?.[0] + 1} - Amount` }}({{
                        entityStore.entityDetail.defaultCurrency
                      }})
                    </p>
                    <p
                      v-else-if="entryLineList?.[item.index]?.amountFC?.[0] === 'BUSINESS_FIELD'"
                      class="cascader-placeholder__tag"
                    >
                      {{ props.currentBusinessData.columns?.[entryLineList?.[item.index]?.amountFC?.[1]] }}
                    </p>
                    <p
                      v-else-if="entryLineList?.[item.index]?.amountFC?.[0] === 'CURRENCY_PRICE'"
                      class="cascader-placeholder__tag"
                    >
                      Currency price
                    </p>
                    <p
                      v-else-if="entryLineList?.[item.index]?.amountFC?.[0] === 'COST_BASIS_OF_SALES'"
                      class="cascader-placeholder__tag"
                    >
                      COST_BASIS_OF_SALES
                    </p>
                    <div v-else-if="entryLineList?.[item.index]?.amountFC?.[0] === 'FORMULA'" class="is-formula">
                      <template
                        v-if="
                          entryLineList?.[item.index]?.amountFC?.[1] &&
                          formulaFormatter(entryLineList?.[item.index]?.amountFC?.[1]).length
                        "
                      >
                        <SvgIcon name="function-math" width="18px" height="18px" />
                        <template
                          v-for="(formula, i) in formulaFormatter(entryLineList?.[item.index]?.amountFC?.[1])"
                          :key="i"
                        >
                          <p v-if="formula?.type !== 'operator'" class="cascader-placeholder__tag">
                            {{ formula.value }}
                          </p>
                          <span v-else class="is-operator"> {{ formula.value }}</span>
                        </template>
                      </template>
                      <span v-else class="is-placeholder">Select...</span>
                    </div>
                  </template>
                  <div
                    v-else-if="
                      ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug) ||
                      (hasGstType && ['NA', ''].includes(entryLineList?.[item.index].gstType))
                    "
                    class="is-formula"
                  >
                    <SvgIcon name="function-math" width="18px" height="18px" />
                    <p class="cascader-placeholder__tag">
                      {{
                        gstFormulaFormat(
                          hasGstType?.gstType,
                          hasGstTypeIndex,
                          ['gst_input', 'gst_output'].includes(
                            currentChartOfAccount(item.chartOfAccountId).value?.slug
                          ),
                          hasGstType?.gstRatio,
                          defaultCurrency
                        )
                      }}
                    </p>
                  </div>
                  <template v-else>
                    <span class="is-placeholder">Select...</span>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <!-- 辅助核算字段 -->
          <template v-if="!!props.entryLines?.[item.index]?.auxiliaryTypes?.length">
            <div
              v-for="(auxiliary, i) in props.entryLines?.[item.index]?.auxiliaryTypes"
              :key="i"
              class="elv-automation-rule-entry-item-select__item"
            >
              <template v-if="!isEmpty(entryLineAuxiliaryValueMap?.[item.index]?.[i]) && auxiliary.name">
                <p class="elv-automation-rule-entry-item-select__item-title">
                  {{ `Auxiliary Code / ${auxiliary.name}` }}
                </p>

                <div class="elv-automation-rule-entry-item-cascader">
                  <el-cascader
                    v-model="entryLineAuxiliaryValueMap[item.index][i].data"
                    popper-class="elv-automation-rule-entry-item-cascader-popper is-filterable"
                    class="el-select is-filterable"
                    :show-all-levels="false"
                    clearable
                    :options="
                      auxiliaryOptions(
                        entryLineAuxiliaryValueMap[item.index][i].auxiliaryCode,
                        entryLineAuxiliaryValueMap[item.index][i].auxiliaryTypeId,
                        item.index
                      ).value
                    "
                  >
                    <template #default="{ node, data }">
                      <el-input
                        v-if="node.isLeaf && data?.disabled"
                        ref="cascaderAuxiliaryInputRefs"
                        v-model="searchQuery"
                        placeholder="Search"
                        class="elv-automation-rule-entry-item-cascader-search"
                        @input="
                          remoteMethod(
                            data.type,
                            item.index,
                            $event,
                            entryLineAuxiliaryValueMap[item.index][i].auxiliaryTypeId
                          )
                        "
                      >
                        <template #prefix><SvgIcon name="select-search" width="18px" height="18px" /></template>
                        <template #suffix>
                          <el-icon v-show="searchLoading" class="is-loading"> <Loading /></el-icon>
                        </template>
                      </el-input>
                      <div v-else class="elv-automation-rule-entry-item-cascader-item">
                        <img v-if="data?.logo" :src="data?.logo" :alt="data?.alt" />
                        <span>{{ data.label }}</span>
                        <p v-if="data?.isDeleted">deleted</p>
                      </div>
                    </template>
                  </el-cascader>

                  <!-- cascader placeholder -->
                  <div class="elv-automation-rule-entry-item-cascader__display">
                    <template v-if="entryLineAuxiliaryValueMap[item.index][i].data?.length">
                      <p
                        v-if="entryLineAuxiliaryValueMap[item.index][i].data?.[0] === 'DIRECT'"
                        class="cascader-placeholder__with-icon"
                      >
                        <template v-if="entryLineAuxiliaryValueMap[item.index][i].auxiliaryCode === 'ENTITY_ACCOUNT'">
                          <img
                            :src="
                              currentAccount(entryLineAuxiliaryValueMap[item.index][i].data?.[1]).value?.platform?.logo
                            "
                            :alt="
                              currentAccount(entryLineAuxiliaryValueMap[item.index][i].data?.[1]).value?.platform?.name
                            "
                          />
                          <span>{{
                            currentAccount(entryLineAuxiliaryValueMap[item.index][i].data?.[1]).value?.name
                          }}</span>
                        </template>
                        <template
                          v-else-if="entryLineAuxiliaryValueMap[item.index][i].auxiliaryCode === 'COUNTERPARTY'"
                        >
                          <!-- <img
                            :src="currentCounterparty(entryLineAuxiliaryValueMap[index][i].data?.[1]).value?.platform?.logo"
                            :alt="currentCounterparty(entryLineAuxiliaryValueMap[index][i].data?.[1]).value?.platform?.name"
                          /> -->
                          <span>{{
                            currentCounterparty(entryLineAuxiliaryValueMap[item.index][i].data?.[1]).value?.name
                          }}</span>
                        </template>
                        <template v-else-if="entryLineAuxiliaryValueMap[item.index][i].auxiliaryCode === 'ITEM'">
                          <span>{{
                            currentAuxiliaryItem(
                              entryLineAuxiliaryValueMap[item.index][i].auxiliaryTypeId,
                              entryLineAuxiliaryValueMap[item.index][i].data?.[1],
                              item.index
                            )
                          }}</span>
                        </template>
                      </p>
                      <p
                        v-else-if="entryLineAuxiliaryValueMap[item.index][i].data?.[0] === 'PATH'"
                        class="cascader-placeholder__tag"
                      >
                        {{ props.currentBusinessData.columns?.[entryLineAuxiliaryValueMap[item.index][i].data?.[1]] }}
                      </p>
                    </template>
                    <template v-else>
                      <span class="is-placeholder">Select...</span>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
        <template
          v-if="
            !props.isGeneralJournalType &&
            item.gstType !== 'NA' &&
            item.gstType !== 'NONE' &&
            item.gstType !== '' &&
            entryLineList.find((i: any) => i.gstType !== 'NA')?.balanceType === item.balanceType &&
            (((item.index + 1) % 2 !== 0 && entryLines?.length !== 2) || entryLines?.length === 2)
          "
        >
          <div class="elv-automation-rule-entry-item__GSTRate">
            {{ t('title.GSTRate') }}: {{ transformI18n(find(journalTypeGstOptions, { value: item.gstType })?.label)
            }}<span v-if="item.gstType !== 'EXEMPT'"
              >, {{ item.gstType !== 'ZERO' ? `${Number(item.gstRatio)}%` : '0%' }}</span
            >
          </div>
        </template>
      </div>
    </template>

    <el-dropdown
      v-if="props.isGeneralJournalType"
      trigger="click"
      placement="bottom-start"
      :show-arrow="false"
      :teleported="false"
      popper-class="elv-add-journal-type__dropdown-popper"
    >
      <elv-button height="32" width="110" class="elv-automation-rule-entry__add">
        <SvgIcon name="add-default" height="16" width="16" fill="#1753EB" />
        {{ t('button.addLine') }}
      </elv-button>
      <template #dropdown>
        <el-dropdown-menu class="elv-add-journal-type__dropdown elv-language" size="large">
          <el-dropdown-item class="elv-add-journal-type__dropdown--item" @click="onAddEntry('Dr')">Dr</el-dropdown-item>
          <el-dropdown-item class="elv-add-journal-type__dropdown--item" @click="onAddEntry('Cr')">Cr</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <FormulaDialog
      ref="formulaDialogRef"
      :model="formulaItem?.value ? 'edit' : 'add'"
      :formulaData="formulaItem?.value || ''"
      :parameters="amountParameters(formulaItem?.key ?? '', formulaItem?.index)"
      @onSaveFormula="onSaveFormula"
    />
  </div>
</template>

<script setup lang="ts">
import ConfigApi from '@/api/ConfigApi'
import AccountsApi from '@/api/AccountsApi'
import { transformI18n } from '@/i18n/index'
import { gstFormulaFormat } from '@/lib/utils'
import FormulaDialog from './FormulaDialog.vue'
import { CurrencyItemType } from '#/ReportsTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'
import { useAccountStore } from '@/stores/modules/accounts'
import { journalTypeGstOptions } from '@/config/reports/index'
import { find, debounce, uniqBy, isNumber, isEmpty, cloneDeep } from 'lodash-es'
import ChartOfAccountOverlayDropdown from '../../ChartOfAccountOverlayDropdown.vue'

const props = defineProps({
  journalTypeData: {
    type: Object,
    required: true
  },
  entryLines: {
    type: Array as () => any[],
    required: true
  },
  auxiliaryTypeList: {
    type: Array<any>,
    required: true
  },
  loading: {
    type: Boolean,
    required: true
  },
  currencyList: {
    type: Array<CurrencyItemType>,
    required: true
  },
  isGeneralJournalType: {
    type: Boolean,
    required: true
  },
  currentBusinessData: {
    type: Object,
    required: true
  },
  amountFormulaList: {
    type: Array as () => any[],
    required: true
  },
  model: {
    type: String,
    default: 'add'
  }
})

const route = useRoute()
const { t, locale } = useI18n()
const entityStore = useEntityStore()
const accountStore = useAccountStore()

const entryLineList: any = toRef(() => props.entryLines)

const entryLineAuxiliaryValueMap = defineModel<any>('entryLineAuxiliaryValueMap', { required: true })
const emit = defineEmits(['onAddEntry', 'onDeleteEntry', 'onChangeSelect', 'onChangeChartOfAccount'])
const currenciesData = ref<CurrencyItemType[]>([])
const currenciesCommonData = ref<CurrencyItemType[]>([])
const originCounterpartyOptions: any = ref([])
const counterpartyOptions: any = ref([])
const searchQuery = ref('')
const formulaDialogRef = ref()
const searchLoading = ref(false)
const showSearchValue = ref(false)
const filterBusinessDataColumns: any = ref([])
const filterEntityAccounts: any = ref([])
const filterAuxiliaryItems: any = ref([])
const formulaItem: any = ref({})
const cascaderAmountRefs: any = ref([])
const cascaderCurrencyInputRefs: any = ref([])
const cascaderAuxiliaryInputRefs: any = ref([])
const cascaderAmountFCRefs: any = ref([])
const defaultCurrency = unref(entityStore.entityDetail.defaultCurrency)

const entityId = computed(() => {
  return route.params?.entityId as string
})

const hasGstTypeIndex = computed(() => {
  return entryLineList.value.findIndex(
    (i: any) => i?.gstType !== 'NONE' && i?.gstType !== 'NA' && i?.gstType !== '' && i?.gstType !== undefined
  )
})

const hasGstType = computed(() => {
  return entryLineList.value[hasGstTypeIndex.value]
})

const columnOptionList = computed(() => {
  if (isEmpty(props.currentBusinessData?.columns)) return []
  return props.currentBusinessData?.columns.map((column: string, index: number) => {
    return {
      label: column,
      value: index
    }
  })
})

const currentChartOfAccount: any = useComputedHook((chartOfAccountId: string) => {
  return find(entityStore.chartOfAccountList, { chartOfAccountId })
})

const currencyOptions: any = useComputedHook((chartOfAccountId: string) => {
  const options = []
  const chartOfAccount = currentChartOfAccount(chartOfAccountId).value
  if (!isEmpty(props.currentBusinessData)) {
    const children: any = [
      {
        label: 'Input',
        value: null,
        disabled: true,
        type: 'BUSINESS_CURRENCY'
      }
    ]
    const columns =
      filterBusinessDataColumns.value.length || showSearchValue.value
        ? filterBusinessDataColumns.value
        : columnOptionList.value
    columns.forEach((column: string) => {
      children.push(column)
    })
    options.push({
      label: props.currentBusinessData.nameLowerCase,
      value: 'BUSINESS_CURRENCY',
      children
    })
  }
  if (currenciesData.value.length || props.currencyList.length) {
    const children: any = [
      {
        label: 'Input',
        value: null,
        disabled: true,
        type: 'DIRECT_CURRENCY'
      }
    ]
    currenciesData.value.forEach((currency: CurrencyItemType) => {
      children.push({
        label: currency.showSymbol,
        value: currency.symbol,
        logo: currency.logo,
        alt: currency.name
      })
    })
    options.push({
      label: 'Currency',
      value: 'DIRECT_CURRENCY',
      children
    })
  }
  if (chartOfAccount?.currencyTypes?.[0] === 'REPORTING') {
    options.push({
      label: 'Currency',
      value: 'REPORTING_CURRENCY',
      children: [
        {
          label: entityStore.entityDetail.underlyingCurrency?.showSymbol,
          value: defaultCurrency,
          logo: entityStore.entityDetail.underlyingCurrency?.logo,
          alt: entityStore.entityDetail.underlyingCurrency?.name
        }
      ]
    })
  }
  return options
})

const currentCurrency: any = useComputedHook((symbol: string) => {
  return find(currenciesCommonData.value, { symbol })
})

const currentAccount: any = useComputedHook((entityAccountId: string) => {
  return find(accountStore.accountList, { entityAccountId })
})

const currentCounterparty: any = useComputedHook((counterpartyId: string) => {
  return find(originCounterpartyOptions.value, { counterpartyId })
})

const currentAuxiliaryItem: any = useComputedHook((auxiliaryTypeId: string, value: string, index: number) => {
  const chartOfAccount = props.isGeneralJournalType
    ? entryLineList.value[index]?.chartOfAccount
    : props.journalTypeData?.entryLines?.[index]?.chartOfAccount

  let auxiliaryType: any = {}
  auxiliaryType = props.entryLines?.[index]?.auxiliaryTypes?.find(
    (item: any) => item.auxiliaryTypeId === auxiliaryTypeId
  )
  if (!auxiliaryType?.auxiliaryTypeId) {
    auxiliaryType = chartOfAccount?.auxiliaryTypes?.find((item: any) => item.auxiliaryTypeId === auxiliaryTypeId)
  }
  if (auxiliaryTypeId === auxiliaryType?.auxiliaryTypeId) {
    const auxiliaryItem = auxiliaryType?.auxiliaryItems?.find((item: any) => item.auxiliaryItemId === value)
    return auxiliaryItem?.value
  }
  return {}
})

const amountOptions: any = computed(() => {
  const options = []
  if (!isEmpty(props.currentBusinessData)) {
    const children: any = [
      {
        label: 'Input',
        value: null,
        disabled: true,
        type: 'BUSINESS_FIELD'
      }
    ]
    const columns = filterBusinessDataColumns.value.length ? filterBusinessDataColumns.value : columnOptionList.value
    columns.forEach((column: string) => {
      children.push(column)
    })
    options.push({
      label: props.currentBusinessData.nameLowerCase,
      value: 'BUSINESS_FIELD',
      children
    })
  }
  options.push({
    label: `Formula`,
    value: 'FORMULA'
  })
  return options
})

const amountParameters = computed(() => {
  return (type: string, index: string | number = '') => {
    const list = cloneDeep(props.amountFormulaList).filter((item: any) => item.type !== 'COST_BASIS_OF_SALES')
    let parameters =
      type === 'amountFC'
        ? list
        : list.filter((item: any) => item.type !== 'ENTRY_INDEX' && item.type !== 'CURRENCY_PRICE')
    if (type === 'amountFC' && index !== '') {
      parameters = parameters.filter(
        (item: any) => (item.type === 'ENTRY_INDEX' && item.value !== index) || item.type !== 'ENTRY_INDEX'
      )
    }
    if (type === 'amountFC') {
      // eslint-disable-next-line no-unused-vars
      parameters = parameters.sort((a: any, b: any) => (a.type === 'CURRENCY_PRICE' ? -1 : 1))
    }
    return parameters
  }
})

const formulaFormatter = computed(() => {
  return (formula: string) => {
    const operators = ['+', '-', '*', '/', '(', ')']
    let list: { value: string; type: 'operator' | 'non-operator' | 'number' }[] = []
    let buffer = '' // 用于暂存连续的非操作符字符
    const formulaIsNumber = (char: string) => char >= '0' && char <= '9'
    for (let i = 0; i < formula.length; i += 1) {
      if (operators.includes(formula[i])) {
        if (buffer.length > 0) {
          const info = formulaIsNumber(buffer) ? 'number' : 'non-operator'
          list.push({ value: buffer, type: info }) // 将暂存的非操作符字符作为一个整体推入数组
          buffer = '' // 重置 buffer
        }
        list.push({ value: formula[i], type: 'operator' }) // 如果当前字符是操作符，直接推入数组
      } else {
        buffer += formula[i] // 将非操作符字符添加到 buffer
      }
    }

    if (buffer.length > 0) {
      // 修改：循环结束后，使用 isNumber 判断 buffer 是否为数字
      const info = formulaIsNumber(buffer) ? 'number' : 'non-operator'
      list.push({ value: buffer, type: info }) // 将最后的非操作符字符作为一个整体推入数组
    }
    list = list.map((item) => {
      if (item.type === 'non-operator') {
        const parameter = props.amountFormulaList.find((val: any) => val.var === item.value)
        return { ...item, value: parameter ? parameter.label : item.value }
      }
      return item
    })
    return list
  }
})

const amountFCOptions: any = computed(() => {
  return (i: number) => {
    const options = []
    if (!isEmpty(props.currentBusinessData)) {
      const children: any = [
        {
          label: 'Input',
          value: null,
          disabled: true,
          type: 'BUSINESS_FIELD'
        }
      ]
      const columns = filterBusinessDataColumns.value.length ? filterBusinessDataColumns.value : columnOptionList.value
      columns.forEach((column: string) => {
        children.push(column)
      })
      options.push({
        label: props.currentBusinessData.nameLowerCase,
        value: 'BUSINESS_FIELD',
        children
      })
    }
    props.entryLines.forEach((entryLine: any, index: number) => {
      if (index !== i) {
        options.push({
          label: `#${index + 1} Amount(${defaultCurrency})`,
          value: index
        })
      }
    })
    if (props.journalTypeData?.brokerInventoryType === 'SALE') {
      options.push({
        label: `Cost Basis of Sales`,
        title: `Cost COST_BASIS_OF_SALES of Sales`,
        value: 'COST_BASIS_OF_SALES'
      })
    }
    options.push({
      label: `Formula`,
      value: 'FORMULA'
    })
    return options
  }
})

const auxiliaryOptions: any = useComputedHook((auxiliaryCode: string, auxiliaryTypeId: string, index: number) => {
  const options = []
  const list: any = [
    {
      label: 'Input',
      value: null,
      disabled: true,
      type: 'DIRECT'
    }
  ]
  switch (auxiliaryCode) {
    case 'ENTITY_ACCOUNT':
      list[0].type = 'ENTITY_ACCOUNT'
      const entityAccountList = filterEntityAccounts.value.length
        ? filterEntityAccounts.value
        : accountStore.accountList
      const dataList =
        props.model === 'edit' ? entityAccountList : entityAccountList.filter((item: any) => item.status === 'NORMAL')
      dataList.forEach((item: any) => {
        list.push({
          value: item.entityAccountId,
          label: item.name,
          logo: item.platform?.logo,
          alt: item.platform?.name,
          isDeleted: item.status !== 'NORMAL'
        })
      })
      options.push({
        label: 'Account',
        value: 'DIRECT',
        children: list
      })
      break
    case 'COUNTERPARTY':
      list[0].type = 'COUNTERPARTY'
      counterpartyOptions.value.forEach((item: any) => {
        list.push({
          value: item.counterpartyId,
          label: item.name
        })
      })
      options.push({
        label: 'Counterparty',
        value: 'DIRECT',
        children: list
      })
      break
    default:
      const chartOfAccount = props.isGeneralJournalType
        ? entryLineList.value[index]?.chartOfAccount
        : props.journalTypeData?.entryLines?.[index]?.chartOfAccounte
      let auxiliaryType: any = {}
      auxiliaryType = props.entryLines?.[index]?.auxiliaryTypes?.find(
        (item: any) => item.auxiliaryTypeId === auxiliaryTypeId
      )
      if (!auxiliaryType?.auxiliaryTypeId) {
        auxiliaryType = chartOfAccount?.auxiliaryTypes?.find((item: any) => item.auxiliaryTypeId === auxiliaryTypeId)
      }
      if (auxiliaryTypeId === auxiliaryType?.auxiliaryTypeId) {
        list[0].type = 'ITEM'
        const auxiliaryItemList = filterAuxiliaryItems.value.length
          ? filterAuxiliaryItems.value
          : auxiliaryType?.auxiliaryItems || []
        auxiliaryItemList.forEach((item: any) => {
          list.push({
            value: item.auxiliaryItemId,
            label: item.value
          })
        })
        options.push({
          label: auxiliaryType.name,
          value: 'DIRECT',
          children: list
        })
      }
      break
  }

  if (!isEmpty(props.currentBusinessData)) {
    const children: any = [
      {
        label: 'Input',
        value: null,
        disabled: true,
        type: 'PATH'
      }
    ]
    const columns = filterBusinessDataColumns.value.length ? filterBusinessDataColumns.value : columnOptionList.value
    columns.forEach((column: string) => {
      children.push(column)
    })
    options.push({
      label: props.currentBusinessData.nameLowerCase,
      value: 'PATH',
      children
    })
  }

  return options
})

const onAddEntry = (balanceType: string) => {
  emit('onAddEntry', balanceType)
}

const onDeleteEntry = (index: string | number) => {
  emit('onDeleteEntry', index)
}

const onChangeChartOfAccount = (index: string | number) => {
  emit('onChangeChartOfAccount', index)
}

const onChangeSelect = (key: string, index: number, value: any) => {
  let data = value
  if ((key === 'amount' || key === 'amountFC') && value?.[0] === 'FORMULA') {
    formulaItem.value = {
      key,
      index,
      value:
        entryLineList.value?.[index]?.[key]?.length > 1 && !isNumber(entryLineList.value?.[index]?.[key]?.[1])
          ? entryLineList.value?.[index]?.[key]?.[1] || ''
          : ''
    }
    if (entryLineList.value?.[index]?.[key]?.[1]) {
      if (isNumber(entryLineList.value?.[index]?.[key]?.[1])) {
        data[1] = ''
      } else {
        data[1] = entryLineList.value?.[index]?.[key]?.[1]
      }
    }
  }
  if ((key === 'amount' || key === 'amountFC') && data === undefined) {
    data = ['FORMULA', entryLineList.value?.[index]?.amountFC?.[1] ?? '']
  }
  emit('onChangeSelect', key, index, data)
  searchQuery.value = ''
  currenciesData.value = props.currencyList
  filterBusinessDataColumns.value = []
  showSearchValue.value = false
}

const onOpenFormula = (key: string, index: number, data: any) => {
  if (data?.value === 'FORMULA') {
    if (key === 'amountFC') {
      cascaderAmountFCRefs.value?.[index]?.togglePopperVisible()
    } else {
      cascaderAmountRefs.value?.[index]?.togglePopperVisible()
    }
    formulaItem.value.key = key
    formulaItem.value.index = index
    formulaItem.value.value =
      entryLineList.value?.[index]?.[key]?.length > 1 && !isNumber(entryLineList.value?.[index]?.[key]?.[1])
        ? entryLineList.value?.[index]?.[key]?.[1] || ''
        : ''
    formulaDialogRef.value?.onCheckFormulaDialog()
  }
}

const onSaveFormula = (formula: string) => {
  if (formulaItem.value) {
    emit('onChangeSelect', formulaItem.value.key, formulaItem.value.index, ['FORMULA', formula])
  }
}

// 搜索Currency
const remoteCurrencyMethod = async (index: number) => {
  const dataIndex = index < 0 ? 0 : index
  const chartOfAccount = currentChartOfAccount(entryLineList.value?.[dataIndex]?.chartOfAccountId ?? '').value
  const isTreasuryAccount = !chartOfAccount?.chartOfAccountId
  if (entryLineList.value?.[dataIndex]?.chartOfAccountId === '' && isTreasuryAccount) return

  if (searchQuery.value) {
    try {
      searchLoading.value = true
      const currencyTypes = chartOfAccount?.currencyTypes ?? []
      const params = {
        recommend: false,
        keywords: [searchQuery.value],
        entityId: entityId.value,
        type: !isTreasuryAccount ? [] : currencyTypes
      }
      const { data } = await ConfigApi.searchCurrencyList(params)
      currenciesData.value = data
      const list = [...cloneDeep(currenciesCommonData.value), ...data]
      currenciesCommonData.value = uniqBy(list, 'symbol')
      showSearchValue.value = true
      setTimeout(() => {
        cascaderCurrencyInputRefs.value?.[index]?.focus()
      }, 300)
    } catch (error) {
      console.log(error)
      currenciesData.value = []
      showSearchValue.value = false
    } finally {
      searchLoading.value = false
    }
  } else {
    showSearchValue.value = false
    currenciesData.value = props.currencyList
    setTimeout(() => {
      cascaderCurrencyInputRefs.value?.[index]?.focus()
    }, 300)
  }
}

const remoteBusinessDataColumns = (type: string, index: number) => {
  if (searchQuery.value) {
    filterBusinessDataColumns.value = columnOptionList.value.filter((data: any) =>
      data?.label?.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
    showSearchValue.value = true
    if (type === 'BUSINESS_CURRENCY') {
      setTimeout(() => {
        cascaderCurrencyInputRefs.value?.[index]?.focus()
      }, 300)
    }
  } else {
    filterBusinessDataColumns.value = []
    showSearchValue.value = false
    if (type === 'BUSINESS_CURRENCY') {
      setTimeout(() => {
        cascaderCurrencyInputRefs.value?.[index]?.focus()
      }, 300)
    }
  }
}

const remoteEntityAccount = () => {
  if (searchQuery.value) {
    filterEntityAccounts.value = accountStore.accountList.filter((item: any) =>
      item.name.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
    showSearchValue.value = true
  } else {
    filterEntityAccounts.value = []
    showSearchValue.value = false
  }
}
const remoteAuxiliaryItem = (index: number, auxiliaryTypeId: string) => {
  if (searchQuery.value) {
    const chartOfAccount = props.isGeneralJournalType
      ? entryLineList.value[index]?.chartOfAccount
      : props.journalTypeData?.entryLines?.[index]?.chartOfAccount
    let auxiliaryType: any = {}
    auxiliaryType = props.entryLines?.[index]?.auxiliaryTypes?.find(
      (item: any) => item.auxiliaryTypeId === auxiliaryTypeId
    )
    if (!auxiliaryType?.auxiliaryTypeId) {
      auxiliaryType = chartOfAccount?.auxiliaryTypes?.find((item: any) => item.auxiliaryTypeId === auxiliaryTypeId)
    }
    const auxiliaryItemList = auxiliaryType?.auxiliaryItems || []
    filterAuxiliaryItems.value = auxiliaryItemList.filter((item: any) =>
      item.value.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
    showSearchValue.value = true
  } else {
    filterAuxiliaryItems.value = []
    showSearchValue.value = false
  }
}

const remoteCounterpartyMethod = async (index: number) => {
  if (searchQuery.value) {
    try {
      searchLoading.value = true
      const params = {
        keywords: searchQuery.value,
        limit: 20,
        page: 1
      }
      const { data } = await AccountsApi.getCounterpartyList(entityId.value, params)
      counterpartyOptions.value = data.list
      showSearchValue.value = true
      setTimeout(() => {
        cascaderAuxiliaryInputRefs.value?.[index]?.focus()
      }, 300)
    } catch (error) {
      console.log(error)
      counterpartyOptions.value = []
      showSearchValue.value = false
    } finally {
      searchLoading.value = false
    }
  } else {
    counterpartyOptions.value = originCounterpartyOptions.value
    showSearchValue.value = false
    setTimeout(() => {
      cascaderAuxiliaryInputRefs.value?.[index]?.focus()
    }, 300)
  }
}

const remoteMethod = debounce((type: string, index: number, $event: any, auxiliaryTypeId: string = '') => {
  console.log('remoteMethod', type, index)
  switch (type) {
    case 'DIRECT_CURRENCY':
      remoteCurrencyMethod(index)
      break
    case 'ENTITY_ACCOUNT':
      remoteEntityAccount()
      break
    case 'ITEM':
      remoteAuxiliaryItem(index, auxiliaryTypeId)
      break
    case 'COUNTERPARTY':
      remoteCounterpartyMethod(index)
      break
    case 'BUSINESS_CURRENCY':
    case 'BUSINESS_FIELD':
    case 'PATH':
      remoteBusinessDataColumns(type, index)
      break
    default:
      break
  }
}, 300)

watch(
  () => props.currencyList,
  () => {
    if (!props.currencyList.length) return
    currenciesData.value = props.currencyList
    const list = [...cloneDeep(props.currencyList)]
    if (entityStore.entityDetail?.underlyingCurrency) {
      list.push(entityStore.entityDetail.underlyingCurrency)
    }
    currenciesCommonData.value = uniqBy(list, 'symbol')
  },
  { immediate: true }
)

watch(
  [() => props.model, () => props.entryLines, () => props.journalTypeData],
  () => {
    const list = [...cloneDeep(currenciesCommonData.value)]
    if (props.model === 'edit' && props.entryLines.length && !isEmpty(props.journalTypeData)) {
      setTimeout(() => {
        props.entryLines.forEach((entryLine: any) => {
          if (entryLine.directUnderlyingCurrency) {
            list.push(cloneDeep(entryLine.directUnderlyingCurrency))
          }
        })
        currenciesCommonData.value = uniqBy(list, 'symbol')
      }, 300)
    }
  },
  { immediate: true }
)

watch(
  () => accountStore.counterpartList.list,
  () => {
    if (!accountStore.counterpartList.list.length) return
    const list = [...cloneDeep(accountStore.counterpartList.list)]
    counterpartyOptions.value = uniqBy(list, 'counterpartyId')
    originCounterpartyOptions.value = uniqBy(list, 'counterpartyId')
  },
  { immediate: true }
)

defineExpose({ formulaFormatter })
</script>

<style lang="scss">
.elv-automation-rule-entryLines {
  width: 100%;
  max-width: 802px;
  min-height: 260px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .elv-automation-rule-entry-item {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: #f9fafb;

    &.is-Cr {
      background: #fffaf9;
    }

    &.is-Dr {
      background: #f5f8ff;
    }

    .elv-automation-rule-entry-title {
      display: flex;
      align-items: center;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      span {
        padding: 2px 4px;
        font-family: 'Barlow';
        font-weight: 500;
        border-radius: 2px;
        background: #feede9;
        color: #f24923;
        margin-right: 10px;

        &.is-Dr {
          background: #e2eafc;
          color: #2368f2;
        }
      }

      p {
        display: flex;
        min-width: 20px;
        height: 21px;
        padding: 2px 4px;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        background: #edf0f3;
        color: #1e2024;
        font-family: 'Barlow';
        font-weight: 500;
        margin-right: 10px;
      }
    }

    .elv-automation-rule-entry-item-select__list {
      width: 100%;
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid #edf0f3;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;

      .elv-automation-rule-entry-item-select__item {
        width: 256.66px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 8px;

        .elv-automation-rule-entry-item-select__item-title {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .el-cascader.el-select {
          .el-input {
            width: 256.66px;
            height: 44px;
          }
        }

        .el-select__selected-item.el-select__placeholder:not(.is-transparent) {
          span {
            color: #1753eb;
            font-family: 'Barlow';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            display: flex;
            padding: 0px 8px;
            justify-content: center;
            align-items: center;
            gap: 2px;
            border-radius: 3px;
            border: 1px solid #dde1e6;
            background: #edf0f3;
            width: fit-content;
          }
        }
      }
    }

    .elv-automation-rule-entry-item-cascader {
      position: relative;
      width: fit-content;
      height: fit-content;

      .elv-automation-rule-entry-item-cascader__display {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
        width: 208px;
        height: 30px;
        background: #fff;
        z-index: 10;
        pointer-events: none;

        .is-placeholder {
          color: #838d95;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }

        .cascader-placeholder__with-icon {
          display: flex;
          align-items: center;
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          max-width: 208px;
          white-space: nowrap;
          overflow: hidden;

          img {
            width: 20px;
            height: 20px;
            display: block;
            border-radius: 50%;
            margin-right: 10px;
          }

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-width: 0; /* 确保flex容器内文本不撑破容器 */
            flex-shrink: 1; /* 允许文本缩小以适应容器 */
          }
        }

        .is-formula {
          display: flex;
          align-items: center;

          svg {
            margin-right: 2px;
          }

          .is-operator {
            color: #1e2024;
            font-family: 'Barlow';
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0 6px;
          }
        }

        .cascader-placeholder__tag {
          display: inline-block;
          width: fit-content;
          padding: 0px 8px;
          border-radius: 3px;
          border: 1px solid #dde1e6;
          background: #edf0f3;
          color: #1753eb;
          font-family: 'Barlow';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          max-width: 208px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.is-disabled {
          background-color: #f9fafb;
        }
      }
    }
  }

  .elv-automation-rule-entry__add {
    svg {
      margin-right: 4px;
      fill: #838d95;
    }

    &:not(.is-disabled) {
      color: #1753eb !important;

      svg {
        fill: #1753eb;
        margin-right: 4px;
      }
    }
  }

  .elv-automation-rule-entry-general-journal {
    display: flex;
    align-items: center;

    .elv-automation-rule-entry-general-journal__balance {
      width: 80px;
      margin-right: 10px;
    }

    .elv-journal-coa-dropdown-content {
      width: 652px;
    }

    .elv-automation-rule-entry-item__delete {
      margin: 0 10px;

      &:not(.is-disabled):hover {
        cursor: pointer;
        fill: #1753eb;
      }

      &.is-disabled {
        fill: #d0d4d9;
        cursor: not-allowed;
      }
    }
  }
}

.el-popper.elv-automation-rule-entry-item-cascader-popper {
  transform: translateY(-11.5px);
  box-shadow: none;
  background-color: transparent;
  border: 0px;

  .el-cascader-panel {
    padding: 1px;
    border-radius: 6px;
  }

  .el-scrollbar.el-cascader-menu {
    border-radius: 6px;
    background: #fff;

    box-shadow:
      0px 2px 6px 0px rgba(0, 0, 0, 0.05),
      0px 0px 1px 0px rgba(0, 0, 0, 0.3);
    height: fit-content;
    position: relative;

    &:first-of-type {
      .el-cascader-menu__wrap.el-scrollbar__wrap {
        height: fit-content;
      }
    }

    &:last-of-type {
      z-index: 10;
    }
  }

  &.is-filterable {
    .el-scrollbar.el-cascader-menu:nth-of-type(2) .el-cascader-menu__list {
      padding-top: 41px;
    }
  }

  .el-cascader-menu__list {
    padding: 0px;
    border-radius: 6px;
    height: fit-content;
    position: relative;

    .el-cascader-node {
      display: flex;
      min-height: 32px;
      height: fit-content;
      box-sizing: border-box;
      align-items: center;
      gap: 8px;
      padding: 0px;
      align-self: stretch;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.is-disabled {
        position: fixed;
        top: 0px;
        background-color: #fff;
        z-index: 10;
      }

      &.in-active-path {
        background-color: #f9fafb;
      }

      .el-cascader-node__label {
        display: flex;
        align-items: center;
        padding: 0px;
        width: 100%;
        min-height: 32px;
        height: fit-content;

        img {
          width: 18px;
          height: 18px;
          display: block;
          border-radius: 50%;
          margin-right: 8px;
        }

        .elv-automation-rule-entry-item-cascader-item {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding: 0px 8px;
          display: flex;
          align-items: center;

          &.is-formula {
            width: 100%;
            height: 32px;
            border-top: 1px solid #edf0f3;
            color: #1753eb;
          }

          p {
            display: flex;
            padding: 6px 8px;
            height: 19px;
            box-sizing: border-box;
            align-items: center;
            color: #fb212f;
            leading-trim: both;
            text-edge: cap;
            font-family: 'Barlow';
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-radius: 14px;
            background: #edf0f3;
            margin-left: 8px;
          }
        }

        .elv-automation-rule-entry-item-cascader-search.el-input {
          height: 40px;
          border-bottom: 1px solid #edf0f3;

          .el-input__wrapper {
            padding: 1px 8px;
            border: none !important;
            box-shadow: none !important;
          }
        }
      }

      &.is-active {
        color: #1753eb;
      }
    }

    .el-icon.el-cascader-node__prefix {
      display: none;
    }
  }
}

.el-popper.el-select__popper.elv-automation-rule-entry-item-select__item-popper {
  .el-select-dropdown__item {
    min-height: 32px;
    height: fit-content;

    &:last-of-type {
      border-top: 1px solid #edf0f3;
    }

    &:nth-of-type(2) {
      border-top: 1px solid #edf0f3;

      &:has(.elv-automation-auxiliary-dropdown__item-special) {
        border-top: 0px;
      }
    }

    .elv-automation-auxiliary-dropdown__item-special {
      display: flex;
      flex-direction: column;
      padding: 8px 0;

      > div {
        display: flex;
        padding: 0px 8px;
        width: fit-content;
        justify-content: center;
        align-items: center;
        gap: 2px;
        border-radius: 3px;
        border: 1px solid #dde1e6;
        background: #edf0f3;
        color: #1753eb;
        font-family: 'Barlow';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 4px;
      }

      p {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
</style>
