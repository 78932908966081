<template>
  <div class="elv-rule-form-condition-journal-type-supplementary">
    <div class="elv-rule-form-condition-journal-type-supplementary__multiple">
      <el-form-item :label="t('common.timezone')" prop="timezone">
        <el-select
          v-model="timezone"
          filterable
          default-first-option
          :placeholder="`${t('common.select')}...`"
          class="is-shorter"
          :suffix-icon="SelectSuffixIcon"
        >
          <el-option
            v-for="(item, index) in timezoneList"
            :key="index"
            :label="`${item.area} (${item.timezone})`"
            :value="item.area"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="t('common.dateTime')" prop="datetime">
        <SingleChoiceSelect
          v-model="datetime"
          :placeholder="`${t('common.select')}...`"
          width="262px"
          filterable
          :options="props.columnOptions"
          :popper-append-to-body="false"
        />
      </el-form-item>

      <el-form-item :label="t('report.referenceNo')">
        <SingleChoiceSelect
          v-model="referenceNo"
          width="262px"
          filterable
          :placeholder="`${t('common.select')}...`"
          :options="props.columnOptions"
          :popper-append-to-body="false"
        />
      </el-form-item>
    </div>

    <el-form-item :label="t('title.additionalItem')">
      <SingleChoiceSelect
        v-model="additionalItem"
        placeholder="Select additional item"
        width="802px"
        multiple
        clearable
        filterable
        collapse-tags
        collapse-tags-tooltip
        :max-collapse-tags="2"
        :options="props.columnOptions"
        :popper-append-to-body="false"
      />
    </el-form-item>
  </div>
</template>

<script setup lang="ts">
import timezoneList from '@/config/timezone'
import SelectSuffixIcon from '@/components/Project/SelectSuffixIcon.vue'
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'

const { t } = useI18n()

const props = defineProps({
  columnOptions: {
    type: Array as () => ElvSelectOptionType[],
    required: true
  }
})

const timezone = defineModel<string>('timezone', { required: true })
const datetime = defineModel<string | number>('datetime', { required: true })
const referenceNo = defineModel<string | number>('referenceNo', { required: true })
const additionalItem = defineModel<number[]>('additionalItem', { required: true })
</script>

<style lang="scss">
.elv-rule-form-condition-journal-type-supplementary {
  margin-bottom: 16px;

  .el-form-item {
    margin-bottom: 0px !important;

    .el-form-item__label {
      color: #636b75 !important;
      font-size: 13px !important;
      font-weight: 600 !important;

      &::after {
        display: none !important;
      }
    }

    .elv-base-single-choice-select.el-select .el-select__wrapper .el-tag {
      border-radius: 3px;
      border: 1px solid #dde1e6;
      background: #edf0f3;
      height: 28px;
      box-sizing: border-box;

      .el-tag__content {
        color: #1753eb;
        font-family: 'Barlow';
      }
    }
  }
}

.elv-rule-form-condition-journal-type-supplementary__multiple {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0px;

  .el-select__wrapper {
    width: 262px;
  }

  .el-select.elv-base-single-choice-select:not(.is-special-select) {
    .el-select__selected-item.el-select__placeholder:not(.is-transparent) {
      span {
        border-radius: 3px;
        border: 1px solid #dde1e6;
        background: #edf0f3;
        display: flex;
        padding: 0px 8px;
        height: 28px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        width: fit-content;
        color: #1753eb;
        font-family: 'Barlow';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }
}
</style>
