<template>
  <el-select
    v-model="model"
    popper-class="elv-automation-rule-condition-input-popper"
    class="elv-automation-rule-condition-long-input is-counterparty-account"
    multiple
    filterable
    allow-create
    clearable
    collapse-tags
    collapse-tags-tooltip
    :max-collapse-tags="2"
    default-first-option
    :reserve-keyword="false"
    @change="() => emit('onChangeCondition')"
  >
    <el-option v-for="item in props.options" :key="item.value" :label="middleEllipsis(item.label)" :value="item.value">
      {{ middleEllipsis(item.label) }}</el-option
    >
  </el-select>
</template>

<script setup lang="ts">
import { middleEllipsis } from '@/lib/utils'

type OptionType = {
  id?: string
  value: string
  label: string
  icon?: string
  alt?: string
}

const props = defineProps({
  options: {
    type: Array as () => OptionType[],
    default: () => []
  },
  width: {
    type: String,
    default: 'fit-content'
  }
})

const counterpartyAccountOptions = ref<OptionType[]>([])
const emit = defineEmits(['onChangeCondition'])

const model = defineModel<any>({ required: true })

// eslint-disable-next-line no-unused-vars
const attrs: any = useAttrs()

watch(
  () => props.options,
  (value) => {
    if (value?.length) counterpartyAccountOptions.value = value
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-automation-rule-condition-long-input.el-select.is-counterparty-account {
  .el-select__wrapper {
    .el-tag {
      border-radius: 3px;
      border: 1px solid #dde1e6;
      background: #edf0f3;
      display: flex;
      padding: 0px 4px 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      box-sizing: border-box;
      width: fit-content;

      .el-tag__content {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        .el-select__tags-text {
          display: flex;
          align-items: center;
        }

        img {
          width: 14px;
          height: 14px;
          display: block;
          border-radius: 50%;
          margin-right: 4px;
        }
      }

      .el-icon.el-tag__close {
        width: 12px;
        height: 12px;
        color: #aaafb6;
        margin-left: 2px;

        &:hover {
          color: #fff;
          background-color: #909399;
        }
      }
    }
  }
}
</style>
