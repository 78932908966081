<template>
  <div class="elv-rule-form-counterparty-supplementary">
    <div class="elv-rule-form-counterparty-supplementary__multiple">
      <el-form-item :label="t('title.basedOnWhichInformation')">
        <SingleChoiceSelect
          v-model="counterpartyField"
          :placeholder="`${t('common.select')}...`"
          width="387px"
          :options="counterpartyFieldOptions"
          :popper-append-to-body="false"
          @change="onCounterpartyFieldChange"
        />
      </el-form-item>
      <el-form-item :label="t('title.RegexForExtractingIdentificationCodeFromMemo')">
        <el-input v-model="extractRegex" :placeholder="`${t('common.input')}...`" />
      </el-form-item>
    </div>
    <el-form-item
      v-if="counterpartyField === 'IDENTIFICATION_CODE'"
      :label="t('title.matchToWhichKeyOfTheElvenCounterpartyIdentificationCode')"
    >
      <SingleChoiceSelect
        v-model="identificationCodeName"
        :placeholder="`${t('common.select')}...`"
        width="786px"
        :options="identificationCodeNameOption"
        :popper-append-to-body="false"
      />
    </el-form-item>
    <el-checkbox v-model="sensitive">{{ t('title.caseSensitiveMatch') }}</el-checkbox>
  </div>
</template>

<script setup lang="ts">
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'

const { t } = useI18n()

const props = defineProps({
  identifyCounterpartyNameList: {
    type: Array as () => string[],
    required: true
  }
})

const counterpartyFieldOptions = reactive([
  { value: 'IDENTIFICATION_CODE', label: t('title.identificationCode') },
  { value: 'CONTACT_IDENTITY', label: t('common.account') }
])

const counterpartyField = defineModel<string>('counterpartyField', { required: true })
const identificationCodeName = defineModel<string>('identificationCodeName', { required: true })
const extractRegex = defineModel<string>('extractRegex', { required: true })
const sensitive = defineModel<boolean>('sensitive', { required: true })

const identificationCodeNameOption = computed(() => {
  return props.identifyCounterpartyNameList.map((item) => {
    return { value: item, label: item }
  })
})

const onCounterpartyFieldChange = () => {
  if (counterpartyField.value !== 'IDENTIFICATION_CODE') {
    identificationCodeName.value = ''
  }
}
</script>

<style lang="scss">
.elv-rule-form-counterparty-supplementary {
  margin-bottom: 16px;
  margin-top: 8px;
  border-radius: 5px;
  background: #f9fafb;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;

  .el-form-item {
    margin-bottom: 0px !important;

    .el-form-item__label {
      color: #636b75 !important;
      font-size: 13px !important;
      font-weight: 600 !important;

      &::after {
        display: none !important;
      }
    }

    .elv-base-single-choice-select.el-select .el-select__wrapper .el-tag {
      border-radius: 3px;
      border: 1px solid #dde1e6;
      background: #edf0f3;
      height: 28px;
      box-sizing: border-box;

      .el-tag__content {
        color: #1753eb;
        font-family: 'Barlow';
      }
    }
  }
}

.elv-rule-form-counterparty-supplementary__multiple {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
  max-width: 802px;

  .el-select__wrapper,
  .el-input {
    width: 389px !important;
  }

  .el-input {
    height: 44px !important;

    .el-input__inner {
      color: #1753eb !important;
      font-style: normal;
      line-height: 28px !important;
    }
  }
}
</style>
