<template>
  <el-select
    v-model="operator"
    popper-class="elv-automation-rule-condition-input-popper"
    :class="[
      operator === 'BETWEEN' || props.activeTab === 'businessData'
        ? 'elv-automation-rule-condition-shorter-input'
        : 'elv-automation-rule-condition-short-input',
      operator === 'BETWEEN' && props.activeTab === 'businessData' ? 'elv-automation-rule-condition-shortest-input' : ''
    ]"
    placeholder="Select"
    @change="() => emit('onChangeType')"
  >
    <el-option
      v-for="(item, i) in amountOperatorOptions"
      :key="i"
      :label="transformI18n(item.label)"
      :value="item.value"
    />
  </el-select>

  <el-input
    v-model="value[0]"
    :class="[
      operator === 'BETWEEN' || props.activeTab === 'businessData'
        ? 'elv-automation-rule-condition-shorter-input'
        : 'elv-automation-rule-condition-short-input',
      operator === 'BETWEEN' && props.activeTab === 'businessData' ? 'elv-automation-rule-condition-shortest-input' : ''
    ]"
    :formatter="(value: string) => formatNumberWithCommas(value)"
    :parser="(value: string) => value.replace(/\$\s?|(,*)/g, '')"
    @change="() => emit('onChangeCondition')"
  />
  <el-input
    v-if="operator === 'BETWEEN'"
    v-model="value[1]"
    class="elv-automation-rule-condition-shorter-input"
    :class="{ 'elv-automation-rule-condition-shortest-input': props.activeTab === 'businessData' }"
    :formatter="(value: string) => formatNumberWithCommas(value)"
    :parser="(value: string) => value.replace(/\$\s?|(,*)/g, '')"
    @change="() => emit('onChangeCondition')"
    ><template #prefix><span>and</span></template></el-input
  >
</template>

<script setup lang="ts">
import { transformI18n } from '@/i18n/index'
import { formatNumberWithCommas } from '@/lib/utils'
import { amountOperatorOptions } from '@/config/index'

const props = defineProps({
  activeTab: {
    type: String,
    default: ''
  }
})

const value = defineModel<any>('value', { required: true })
const operator = defineModel<any>('operator', { required: true })

const emit = defineEmits(['onChangeType', 'onChangeCondition'])
</script>

<style lang="scss" scoped></style>
