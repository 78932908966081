<template>
  <div class="elv-automation-table-row__cell-conditions">
    <span
      v-if="props.params?.data?.systemAutomationRuleId && props.activeTab === 'transfer'"
      class="elv-automation-table-row__cell-action__label is-system"
      >{{ props.params?.data?.conditionDescription }}
    </span>
    <span
      v-if="
        (props.params?.data?.type === 'SYSTEM' || !props.params?.data?.conditions?.length) &&
        props.activeTab === 'counterpartyTransformer'
      "
      class="elv-automation-table-row__cell-action__label is-system"
      >All transactions
    </span>
    <template v-else>
      <div
        v-for="(condition, index) in props.params?.data?.conditions"
        :key="index"
        class="elv-automation-table-cell-conditions__item"
      >
        <span class="elv-automation-table-row__cell-action__label">
          {{ currentConditionType(condition) }}
        </span>
        <p class="elv-automation-table-cell-conditions__item-is">
          <span
            v-if="
              (!['DATETIME', 'ENDING_DATE', 'AMOUNT', 'AMOUNT_FC', 'MEMO'].includes(condition.type) &&
                ['transfer', 'derivativeAdjustment'].includes(props.activeTab)) ||
              (['businessData', 'valuation'].includes(props.activeTab) &&
                ['CURRENCY_TYPE'].includes(condition.columnType))
            "
          >
            {{
              ['ORIGIN', 'DIRECTION', 'POSITION_SIDE'].includes(condition.type) ||
              ['businessData', 'valuation'].includes(props.activeTab)
                ? t('common.is')
                : t('common.in')
            }}
          </span>

          <span
            v-else-if="
              condition.columnType === 'DATETIME' ||
              condition.type === 'ENDING_DATE' ||
              condition.type === 'DATETIME' ||
              (condition.type === '' && datetimeTypes.includes(condition.operator))
            "
            >{{ dateOperator(condition.operator) }}</span
          >
          <span
            v-else-if="props.activeTab !== 'transfer' && condition.type === '' && condition.operator === 'INCLUDES'"
            >{{ t('common.includes') }}</span
          >
          <span
            v-else-if="
              ['AMOUNT', 'AMOUNT_FC'].includes(condition.type) ||
              (condition.type === '' && amountTypes.includes(condition.operator)) ||
              props.activeTab !== 'transfer'
            "
            >{{
              ['businessData', 'valuation', 'derivativeAdjustment'].includes(props.activeTab) &&
              condition.operator === 'BETWEEN'
                ? t('common.isBetween')
                : amountOperator(condition.operator)
            }}</span
          >
          <span v-else-if="condition.type === 'MEMO'">{{ amountOperator(condition.operator) }}</span>
        </p>
        <div class="elv-automation-table-cell-conditions__item-value">
          <template v-if="['TRANSACTION_TYPE', 'GAIN_LOSS_TYPE', 'TRADE_TYPE'].includes(condition.type)">
            <template v-if="props.activeTab === 'transfer'">
              <span v-for="(item, i) in condition.value" :key="i">
                <template v-if="i > 0">,</template>
                {{ transformI18n(conditionTypeData(item)) }}
              </span>
            </template>
            <span v-else>{{ transformI18n(conditionTypeData(condition.value)) }}</span>
          </template>

          <template v-else-if="condition.type === 'PLATFORM_TYPE'">
            <template v-if="!['businessData', 'valuation'].includes(props.activeTab)">
              <span v-for="(item, i) in condition.value" :key="i">
                <template v-if="i > 0">,</template>
                {{ platformType(item) }}
              </span>
            </template>
            <span v-else>{{ platformType(condition.value) }}</span>
          </template>

          <template
            v-else-if="
              condition.type === 'ENTITY_ACCOUNT' ||
              condition.type === 'COUNTERPARTY' ||
              condition.type === 'COUNTERPARTY_TAG' ||
              condition.type === 'ENTITY_ACCOUNT_TAG'
            "
          >
            <template v-if="props.activeTab === 'counterpartyTransformer' && condition.type === 'ENTITY_ACCOUNT'">
              <span v-for="(item, i) in condition.entityAccounts" :key="i">
                <template v-if="i > 0">,</template>
                {{ item?.name }}
              </span></template
            >
            <template v-else-if="!['businessData', 'valuation'].includes(props.activeTab)">
              <span v-for="(item, i) in condition.value" :key="i">
                <template v-if="i > 0">,</template>
                {{ item?.entityAccount?.name ?? item.name }}
              </span>
            </template>
            <span v-else>{{
              condition.value?.name
                ? condition.value?.name
                : condition.operator === 'IS_NULL'
                  ? 'Empty'
                  : formatInputAddress(condition?.value?.identity ?? condition?.value?.cardNumber)
            }}</span>
          </template>

          <template v-else-if="['COUNTERPARTY_IDENTITY', 'MEMO'].includes(condition.type)">
            <template v-if="props.activeTab === 'transfer' && condition.type === 'COUNTERPARTY_IDENTITY'">
              <span v-for="(item, i) in condition.value" :key="i">
                <template v-if="i > 0">,</template>
                {{ formatInputAddress(item, 4, -4) }}
              </span>
            </template>
            <span v-else>{{ condition.value }}</span>
          </template>

          <template v-else-if="condition.type === 'DIRECTION'">
            <span>{{ condition.value === 'IN' ? t('report.inflow') : t('report.outflow') }}</span>
          </template>

          <template v-else-if="condition.type === 'ORIGIN'">
            <span>{{ condition.value === 'EXCHANGE' ? t('common.exchange') : t('common.client') }}</span>
          </template>

          <template v-else-if="condition.type === 'POSITION_SIDE'">
            <span>{{ condition.value === 'LONG' ? t('project.costBasis.long') : t('project.costBasis.short') }}</span>
          </template>

          <template
            v-else-if="['DATETIME', 'ENDING_DATE'].includes(condition.type) || condition.columnType === 'DATETIME'"
          >
            <SvgIcon name="source-rule-calendar" width="12" height="12" fill="#1343BF" />
            <template v-if="condition.operator === 'BETWEEN'">
              <span>{{ dateFormatting(condition.value[0], 'date') }}</span>
              <span>&nbsp;&nbsp;- {{ dateFormatting(condition.value[1], 'date') }}</span>
            </template>
            <span v-else>{{ condition.value ? dateFormatting(condition.value[0], 'date') : '' }}</span>
          </template>

          <template v-else-if="['AMOUNT', 'AMOUNT_FC'].includes(condition.type) || condition.columnType === 'NUMBER'">
            <span>{{ condition.value?.[0] }}</span>
            <span v-if="condition.value?.[1]">&nbsp;&nbsp;- {{ condition.value?.[1] }}</span>
          </template>

          <template v-else-if="condition.type === 'CURRENCY' || condition.type === 'PLATFORM'">
            <template v-if="props.activeTab === 'counterpartyTransformer' && condition.type === 'PLATFORM'">
              <span v-for="(item, i) in condition.platforms" :key="i">
                <template v-if="i > 0">,</template>
                {{ item?.name }}
              </span></template
            >
            <template v-else-if="!['businessData', 'valuation'].includes(props.activeTab)">
              <span v-for="(item, i) in condition.value" :key="i">
                <template v-if="i > 0">,</template>
                {{
                  condition.type === 'PLATFORM'
                    ? (item?.platform?.name ?? item?.name)
                    : (item?.showSymbol ?? item?.currency?.showSymbol)
                }}
              </span>
            </template>
            <span> {{ condition.type === 'PLATFORM' ? condition.value?.name : condition.value?.showSymbol }}</span>
          </template>

          <template v-else-if="condition.type === 'ASSET'">
            <span v-for="(item, i) in condition.value" :key="i">
              <template v-if="i > 0">,</template>
              {{ item?.asset?.derivative?.name }}
            </span>
          </template>

          <template v-else-if="condition.columnType === 'TEXT'">
            <span v-if="typeof condition.value === 'string'">{{ condition.value }}</span>
            <span v-for="(item, i) in condition.value" v-else :key="i"
              ><template v-if="i > 0">&nbsp;,&nbsp;</template>{{ item }}
            </span>
          </template>

          <template v-else-if="condition.type === ''">
            <template v-if="typeof condition.value === 'string'"
              ><span>{{ condition.value }} </span>
            </template>
            <span v-for="(item, i) in condition.value" v-else :key="i"
              ><template v-if="i > 0">&nbsp;&nbsp;- </template>{{ item }}</span
            >
          </template>

          <template v-else>
            <span>{{
              !condition?.type ? condition?.value : condition?.type === 'CURRENCY_FIAT' ? 'Fiat' : 'Crypto Assets'
            }}</span>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { find, flatMap } from 'lodash-es'
import { transformI18n } from '@/i18n/index'
import { useEntityStore } from '@/stores/modules/entity'
import { dateFormatting, formatInputAddress } from '@/lib/utils'
import { tradeType, gainLossType, transactionType } from '@/config/index'

const props = defineProps({
  activeTab: {
    type: String,
    default: ''
  },
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const entityStore = useEntityStore()
const datetimeTypes = ['ON', 'BEFORE', 'AFTER', 'BETWEEN']
const amountTypes = ['EQ', 'NE', 'GTE', 'LTE', 'GT', 'LT', 'BETWEEN']

const platformType = computed(() => {
  return (type: string) => {
    let text = ''
    switch (type) {
      case 'CHAIN':
        text = t('common.onChain')
        break
      case 'EXCHANGE':
        text = t('common.exchange')
        break
      case 'BANK':
        text = t('common.bank')
        break
      case 'CUSTODY':
        text = t('common.custody')
        break
      case 'PAYMENT':
        text = t('common.payment')
        break
      default:
        text = ''
        break
    }
    return text
  }
})

const conditionTypeData = computed(() => {
  return (type: string) => {
    if (props.activeTab === 'transfer') {
      let data = ''
      const transactionTypeList = flatMap(transactionType, 'list')
      transactionTypeList.forEach((item) => {
        if (item.value === type) {
          data = item.label
        }
      })
      return data
    }
    if (props.activeTab === 'trade') {
      return find(tradeType, { value: type })?.label ?? ''
    }
    if (props.activeTab === 'gainLoss') {
      return find(gainLossType, { value: type })?.label ?? ''
    }
    return ''
  }
})

const currentConditionType = computed(() => {
  return (row: any) => {
    let label = ''
    switch (row?.type) {
      case 'TRANSACTION_TYPE':
      case 'TRADE_TYPE':
      case 'GAIN_LOSS_TYPE':
        label = t('common.type')
        break
      case 'PLATFORM_TYPE':
        label = t('common.platformType')
        break
      case 'ORIGIN': {
        label = t('common.origin')
        break
      }
      case 'ASSET': {
        label = t('common.asset')
        break
      }
      case 'POSITION_SIDE':
        label = t('report.positionAndSide')
        break
      case 'PLATFORM':
        label = t('common.platform')
        break
      case 'CURRENCY':
      case 'CURRENCY_FIAT':
      case 'CURRENCY_CRYPTO':
        label =
          props.activeTab === 'transfer'
            ? t('common.currency')
            : props.params?.data?.businessDataType?.columns?.[row?.column]
        if (row?.jsonPath) {
          label = `${label}.${row.jsonPath}`
        }
        if (props.activeTab === 'derivativeAdjustment') {
          label = t('valuation.assets.marginAsset')
        }
        break
      case 'DATETIME':
      case 'ENDING_DATE':
        label = t('common.date')
        break
      case 'MEMO':
        label = t('report.memo')
        break
      case 'DIRECTION':
        label = t('common.direction')
        break
      case 'AMOUNT':
        label = t('common.amount')
        if (props.activeTab === 'derivativeAdjustment') {
          label = t('valuation.future.unrealizedAndChange')
        }
        break
      case 'AMOUNT_FC':
        label = `${t('common.amount')} (${entityStore.entityDetail?.defaultCurrency})`
        if (props.activeTab === 'derivativeAdjustment') {
          label = `${t('valuation.future.unrealizedAndChange')} (${entityStore.entityDetail?.defaultCurrency})`
        }
        break
      case 'ENTITY_ACCOUNT':
        label = t('common.account')
        break
      case 'ENTITY_ACCOUNT_TAG':
        label = t('common.accountTag')
        break
      case 'COUNTERPARTY':
        label = t('common.counterparty')
        break
      case 'COUNTERPARTY_TAG':
        label = t('common.counterpartyTag')
        break
      case 'COUNTERPARTY_IDENTITY':
        label = t('common.counterpartyAccount')
        break
      case 'NUMBER':
        label = props.params?.data?.businessDataType?.columns?.[row?.column]
        break
      case '':
        label = props.params?.data?.businessDataType?.columns?.[row?.column]
        if (row?.jsonPath) {
          label = `${label}.${row.jsonPath}`
        }
        break
      default:
        label = row.type
        break
    }

    if (['businessData', 'valuation'].includes(props.activeTab) && row?.columnType) {
      label = props.params?.data?.businessDataType?.columns?.[row?.column]
    }
    return label
  }
})

const dateOperator = computed(() => {
  return (operator: string) => {
    let label = ''
    switch (operator) {
      case 'EQ':
      case 'ON':
        label = props.activeTab === 'transfer' ? t('common.on') : t('common.isOn')
        break
      case 'GT':
      case 'BEFORE':
        label = props.activeTab === 'transfer' ? t('common.before') : t('common.isBefore')
        break
      case 'LT':
      case 'AFTER':
        label = props.activeTab === 'transfer' ? t('common.after') : t('common.isAfter')
        break
      default:
        label = t('common.isBetween')
        break
    }
    return label
  }
})

const amountOperator = computed(() => {
  return (operator: string) => {
    let label = ''
    switch (operator) {
      case 'EQ':
        label = props.activeTab === 'transfer' ? t('common.equals') : '='
        break
      case 'NE':
        label = props.activeTab === 'transfer' ? t('common.doesNotEqual') : '≠'
        break
      case 'GTE':
        label = props.activeTab === 'transfer' ? t('common.isGreaterThanOrEqualTo') : '>='
        break
      case 'GT':
        label = props.activeTab === 'transfer' ? t('common.isGreaterThan') : '>'
        break
      case 'LTE':
        label = props.activeTab === 'transfer' ? t('common.isLessThanOrEqualTo') : '<='
        break
      case 'LT':
        label = props.activeTab === 'transfer' ? t('common.isLessThan') : '<'
        break
      case 'INCLUDES':
        label = t('common.includes')
        break
      case 'EXCLUDES':
        label = t('common.excludes')
        break
      case 'NOT_IN':
        label = t('common.notIn')
        break
      case 'IN':
        label = t('common.in')
        break
      default:
        label = props.activeTab === 'transfer' ? t('common.isBetween') : '~'
        break
    }
    return label
  }
})
</script>

<style lang="scss" scoped>
.elv-automation-table-row__cell-conditions {
  padding: 11px 0;
  display: flex;
  flex-direction: column;

  .elv-automation-table-cell-conditions__item {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .elv-automation-table-cell-conditions__item-type {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #0e0f11;
  }

  .elv-automation-table-cell-conditions__item-is {
    margin-right: 6px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #636b75;

    span {
      white-space: nowrap;
    }

    span:nth-child(2) {
      margin-left: 4px;
    }
  }

  .elv-automation-table-cell-conditions__item-value {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 18px;

    svg,
    img {
      display: block;
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }

    svg {
      fill: #1343bf;
    }

    span {
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16.5px;
      white-space: nowrap;
    }
  }

  .elv-automation-table-row__cell-action__value {
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-left: 4px;
  }
}
</style>
